const modelosBMW = [
  { chave: "", valor: "" },
  { chave: "serie1", valor: "Série 1" },
  { chave: "serie2", valor: "Série 2" },
  { chave: "serie3", valor: "Série 3" },
  { chave: "serie4", valor: "Série 4" },
  { chave: "serie5", valor: "Série 5" },
  { chave: "serie6", valor: "Série 6" },
  { chave: "serie7", valor: "Série 7" },
  { chave: "serie8", valor: "Série 8" },
  { chave: "x1", valor: "X1" },
  { chave: "x2", valor: "X2" },
  { chave: "x3", valor: "X3" },
  { chave: "x4", valor: "X4" },
  { chave: "x5", valor: "X5" },
  { chave: "x6", valor: "X6" },
  { chave: "x7", valor: "X7" },
  { chave: "i3", valor: "i3" },
  { chave: "i4", valor: "i4" },
  { chave: "iX1", valor: "iX1" },
  { chave: "iX3", valor: "iX3" },
  { chave: "iX4", valor: "iX4" },
  { chave: "iX5", valor: "iX5" },
  { chave: "iX6", valor: "iX6" },
  { chave: "m2", valor: "M2" },
  { chave: "m3", valor: "M3" },
  { chave: "m4", valor: "M4" },
  { chave: "m5", valor: "M5" },
  { chave: "m8", valor: "M8" },
  { chave: "z4", valor: "Z4" },
  { chave: "840i", valor: "840i" },
  { chave: "850i", valor: "850i" },
  { chave: "alpina-b3", valor: "Alpina B3" },
  { chave: "alpina-b7", valor: "Alpina B7" },
  { chave: "alpina-xd3", valor: "Alpina XD3" },
  { chave: "alpina-xd4", valor: "Alpina XD4" },
  { chave: "alpina-xd7", valor: "Alpina XD7" },
  { chave: "alpina-b5", valor: "Alpina B5" },
  { chave: "alpina-b6", valor: "Alpina B6" },
  { chave: "alpina-d3", valor: "Alpina D3" },
  { chave: "alpina-d4", valor: "Alpina D4" },
  { chave: "alpina-d5", valor: "Alpina D5" },
  { chave: "alpina-d7", valor: "Alpina D7" },
  { chave: "alpina-b4", valor: "Alpina B4" },
  { chave: "alpina-b8", valor: "Alpina B8" },
  { chave: "m135i", valor: "M135i" },
  { chave: "m235i", valor: "M235i" },
  { chave: "m550i", valor: "M550i" },
  { chave: "m760i", valor: "M760i" },
  { chave: "x3-m", valor: "X3 M" },
  { chave: "x4-m", valor: "X4 M" },
  { chave: "x5-m", valor: "X5 M" },
  { chave: "x6-m", valor: "X6 M" },
];

export default modelosBMW;
