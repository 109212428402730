<template>
  <v-app>
    <v-form ref="form">
      <div class="teste">
        <div class="segura">
          <v-row v-if="!isEditing">
            <v-col>
              <v-autocomplete
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="newCar.Marca"
                :items="marcas"
                label="Marca do Carro"
                outlined
                @change="updateModelos"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>

            <v-col>
              <v-autocomplete
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="newCar.Modelo"
                :items="modelos[newCar.Marca]"
                label="Modelo do Carro"
                outlined
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                hide-details
                :rules="[rules.required]"
                v-model="newCar.Versao"
                label="Versão"
                outlined
              />
            </v-col>

            <v-col>
              <v-autocomplete
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="newCar.Cambio"
                :items="tiposCambio"
                label="Cambio"
                outlined
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-autocomplete
                hide-details
                :rules="[rules.required]"
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="newCar.Ano"
                :items="anosDisponiveis"
                label="Ano do Carro"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                hide-details
                :rules="[rules.required]"
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="newCar.Direcao"
                :items="listaDirecoes"
                label="Direção do Carro"
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                hide-details
                :rules="[rules.required]"
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="newCar.AnoModelo"
                :items="anosDisponiveis"
                label="Ano do Modelo"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                hide-details
                :rules="[rules.required]"
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="newCar.Carroceria"
                :items="listaCarrocerias"
                label="Carrocerias do Carro"
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-autocomplete
                hide-details
                :rules="[rules.required]"
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="newCar.Cor"
                :items="listaCores"
                label="Cor do Carro"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                :rules="[rules.required]"
                v-model="newCar.FinalPlaca"
                label="Final da Placa"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                hide-details
                :rules="[rules.required]"
                v-mask="'##########'"
                v-model="newCar.Quilometragem"
                label="Quilometragem"
                outlined
              />
            </v-col>

            <v-col>
              <v-autocomplete
                hide-details
                :rules="[rules.required]"
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="newCar.Combustivel"
                :items="listaCombustiveis"
                label="Combustível"
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                hide-details
                :rules="[rules.required]"
                v-mask="'##########'"
                v-model="newCar.Preco"
                label="Preço do Carro"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                v-mask="'##########'"
                v-model="newCar.PrecoFake"
                label="Preço fake do Carro (Opcional)"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-autocomplete
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="newCar.Cidade"
                :items="listaCidades"
                label="Cidade (Opcional)"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                v-mask="'SSS-#X##'"
                v-model="newCar.Placa"
                label="Placa do Carro"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea
                hide-details
                v-model="newCar.Descricao"
                label="Descrição do Carro"
                outlined
              ></v-textarea>
            </v-col>
            <v-col>
              <v-checkbox
                hide-details
                v-model="newCar.Manual"
                label="Possui manual"
                outlined
              ></v-checkbox>
              <v-checkbox
                hide-details
                v-model="newCar.ChaveReserva"
                label="Possui chave reserva"
                outlined
              ></v-checkbox>
            </v-col>
          </v-row>

          <h1>Opcionais</h1>
          <v-row>
            <v-col cols="6">
              <v-checkbox
                v-model="newCar.Opcionais"
                value="Ar Condicionado"
                label="Ar Condicionado"
              ></v-checkbox>
              <v-checkbox
                v-model="newCar.Opcionais"
                value="Vidro Elétrico"
                label="Vidro Elétrico"
              ></v-checkbox>
              <v-checkbox
                v-model="newCar.Opcionais"
                value="Trava"
                label="Trava"
              ></v-checkbox>
              <v-checkbox
                v-model="newCar.Opcionais"
                value="Airbag"
                label="Airbag"
              ></v-checkbox>
              <v-checkbox
                v-model="newCar.Opcionais"
                value="Alarme"
                label="Alarme"
              ></v-checkbox>
              <v-checkbox
                v-model="newCar.Opcionais"
                value="Interface"
                label="Interface"
              ></v-checkbox>
              <v-checkbox
                v-model="newCar.Opcionais"
                value="Engate Reboque"
                label="Engate Reboque"
              ></v-checkbox>
            </v-col>

            <v-col cols="6">
              <v-checkbox
                v-model="newCar.Opcionais"
                value="Multimídia"
                label="Multimídia"
              ></v-checkbox>
              <v-checkbox
                v-model="newCar.Opcionais"
                value="Sensor de Re"
                label="Sensor de Ré"
              ></v-checkbox>
              <v-checkbox
                v-model="newCar.Opcionais"
                value="Camera de Re"
                label="Câmera de Ré"
              ></v-checkbox>
              <v-checkbox
                v-model="newCar.Opcionais"
                value="Volante Ajustável"
                label="Volante Ajustável"
              ></v-checkbox>
              <v-checkbox
                v-model="newCar.Opcionais"
                value="Banco Ajust."
                label="Banco com Regulagem de Altura"
              ></v-checkbox>
              <v-checkbox
                v-model="newCar.Opcionais"
                value="Cinto 3 Pontas"
                label="Cinto 3 Pontas"
              ></v-checkbox>
              <v-checkbox
                v-model="newCar.UnicoDono"
                value="true"
                label="Unico Dono"
              ></v-checkbox>
              <v-checkbox
                v-model="newCar.AceitaTroca"
                value="true"
                label="Aceita Troca"
              ></v-checkbox>
              <!-- ... (mais checkboxes para a segunda coluna) -->
            </v-col>
          </v-row>
          <h1>Paginas especiais</h1>
          <v-row>
            <v-col>
              <v-checkbox
                v-model="newCar.MaisProcurado"
                label="Mais Procurados"
              ></v-checkbox>
            </v-col>

            <v-col>
              <v-checkbox
                v-model="newCar.Destaque"
                label="Destaques"
              ></v-checkbox>
            </v-col>

            <v-col>
              <v-checkbox
                v-model="newCar.Recentes"
                label="Recentes (Home)"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-file-input
                v-if="isEditing"
                v-model="newCar.Fotos"
                label="Fotos do Carro"
                show-size
                outlined
                multiple
                @change="handlePhotoUpload"
                hide-details
              ></v-file-input>
              <v-file-input
                v-else
                hide-details
                v-model="newCar.Fotos"
                label="Fotos do Carro"
                show-size
                outlined
                multiple
                @change="handlePhotoUpload"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-right">
              <v-btn
              aria-label="Atualiza/adicionar Carro"
                @click="isEditing ? updateCar() : addNewCar()"
                color="primary"
                outlined
                :loading="loading"
              >
                {{ isEditing ? "Atualizar Carro" : "Adicionar Carro" }}
              </v-btn>
              <v-btn  aria-label="Deletar Carro" v-if="isEditing" @click="deleteCar" color="error" outlined
                >Deletar Carro</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </div>
    </v-form>
  </v-app>
</template>

<script>
import CreateCarMixin from "../mixins/CreateCarMixin";
export default {
  mixins: [CreateCarMixin],
};
</script>

<style scoped>
.teste {
}
.text-center {
  text-align: center;
  margin-top: 20px;
}

.v-autocomplete,
.v-select,
.v-text-field,
.v-file-input,
.v-checkbox .v-textarea {
  margin-bottom: 15px;
  background-color: white;
}

.text-right {
  margin-top: 15px;
}

.my-4 {
  margin: 20px 0;
}

.check {
  width: 200px !important;
}

.segura {
  margin: auto;
  width: 80%;
}
</style>
