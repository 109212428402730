import emailjs from 'emailjs-com';

const emailjsConfig = {
  serviceID: 'service_5xam0yb', // Substitua pelo seu Service ID
  templateID: 'template_42xnyjm', // Substitua pelo seu Template ID
  userID: 'Qxv3bWUKoL6RgI1Cu', // Substitua pelo seu User ID
};

emailjs.init(emailjsConfig.userID);

export default emailjs;
