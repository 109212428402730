const cidades = [
    { chave: "Goiânia", valor: "Goiânia" },
    { chave: "Aparecida de Goiânia", valor: "Aparecida de Goiânia" },
    { chave: "Anápolis", valor: "Anápolis" },
    { chave: "Luziânia", valor: "Luziânia" },
    { chave: "Trindade", valor: "Trindade" },
    { chave: "Formosa", valor: "Formosa" },
    { chave: "Águas Lindas de Goiás", valor: "Águas Lindas de Goiás" },
    { chave: "Valparaíso de Goiás", valor: "Valparaíso de Goiás" },
    { chave: "Novo Gama", valor: "Novo Gama" },
    { chave: "Senador Canedo", valor: "Senador Canedo" },
    { chave: "Catalão", valor: "Catalão" },
    { chave: "Itumbiara", valor: "Itumbiara" },
    { chave: "Jataí", valor: "Jataí" },
    { chave: "Planaltina", valor: "Planaltina" },
    { chave: "Caldas Novas", valor: "Caldas Novas" },
    { chave: "Morrinhos", valor: "Morrinhos" },
    { chave: "Porangatu", valor: "Porangatu" },
    { chave: "Inhumas", valor: "Inhumas" },
    { chave: "Ceres", valor: "Ceres" },
    { chave: "Quirinópolis", valor: "Quirinópolis" },
    { chave: "Jaraguá", valor: "Jaraguá" },
    { chave: "Goianésia", valor: "Goianésia" },
    { chave: "Niquelândia", valor: "Niquelândia" },
    { chave: "Uruaçu", valor: "Uruaçu" },
    { chave: "Iporá", valor: "Iporá" },
    { chave: "Goianira", valor: "Goianira" },
    { chave: "Pires do Rio", valor: "Pires do Rio" },
    { chave: "Itapaci", valor: "Itapaci" },
    { chave: "Bela Vista de Goiás", valor: "Bela Vista de Goiás" },
    { chave: "Santa Helena de Goiás", valor: "Santa Helena de Goiás" },
    { chave: "Minutos Gerais", valor: "Minutos Gerais" },
    { chave: "Itapuranga", valor: "Itapuranga" },
    { chave: "Santo Antônio do Descoberto", valor: "Santo Antônio do Descoberto" },
    { chave: "São Luís de Montes Belos", valor: "São Luís de Montes Belos" },
    { chave: "Palmeiras de Goiás", valor: "Palmeiras de Goiás" },
    { chave: "Pontalina", valor: "Pontalina" },
    { chave: "Padre Bernardo", valor: "Padre Bernardo" },
    { chave: "Cavalcante", valor: "Cavalcante" },
    { chave: "Padre Bernardo", valor: "Padre Bernardo" },
    { chave: "Cavalcante", valor: "Cavalcante" },
    { chave: "Campos Belos", valor: "Campos Belos" },
    { chave: "Iporá", valor: "Iporá" },
    { chave: "Cristalina", valor: "Cristalina" },
    { chave: "Itaberaí", valor: "Itaberaí" },
    { chave: "Goianira", valor: "Goianira" },
    { chave: "Cidade Ocidental", valor: "Cidade Ocidental" },
    { chave: "Ceres", valor: "Ceres" },
    { chave: "Alexânia", valor: "Alexânia" },
    { chave: "Brasília", valor: "Brasília" },
    { chave: "Taguatinga", valor: "Taguatinga" },
    { chave: "Ceilândia", valor: "Ceilândia" },
    { chave: "Gama", valor: "Gama" },
    { chave: "Planaltina", valor: "Planaltina" },
    { chave: "Samambaia", valor: "Samambaia" },
    { chave: "Recanto das Emas", valor: "Recanto das Emas" },
    { chave: "Águas Claras", valor: "Águas Claras" },
    { chave: "Sobradinho", valor: "Sobradinho" },
    { chave: "Santa Maria", valor: "Santa Maria" },
    { chave: "Sudoeste/Octogonal", valor: "Sudoeste/Octogonal" },
    { chave: "Riacho Fundo", valor: "Riacho Fundo" },
    { chave: "Núcleo Bandeirante", valor: "Núcleo Bandeirante" },
    { chave: "Cruzeiro", valor: "Cruzeiro" },
    { chave: "Lago Sul", valor: "Lago Sul" },
    { chave: "Brazlândia", valor: "Brazlândia" },
    { chave: "São Sebastião", valor: "São Sebastião" },
    { chave: "Itapoã", valor: "Itapoã" },
    { chave: "Vicente Pires", valor: "Vicente Pires" },
    { chave: "Fercal", valor: "Fercal" },
    { chave: "SCIA", valor: "SCIA" },
    { chave: "Jardim Botânico", valor: "Jardim Botânico" },
    { chave: "Candangolândia", valor: "Candangolândia" },
    { chave: "Varjão", valor: "Varjão" },
    { chave: "Lago Norte", valor: "Lago Norte" },
    { chave: "Park Way", valor: "Park Way" },
    { chave: "Guará", valor: "Guará" },
    { chave: "Estrutural", valor: "Estrutural" },
    { chave: "Setor de Indústrias", valor: "Setor de Indústrias" },
    { chave: "SIA", valor: "SIA" },
    { chave: "Sobradinho II", valor: "Sobradinho II" },
    { chave: "Sol Nascente/Pôr do Sol", valor: "Sol Nascente/Pôr do Sol" },
    { chave: "SCIA", valor: "SCIA" },
    { chave: "Jardins Mangueiral", valor: "Jardins Mangueiral" },
    { chave: "Santa Maria", valor: "Santa Maria" },
    { chave: "Setor Complementar de Indústria e Abastecimento", valor: "Setor Complementar de Indústria e Abastecimento" },
    { chave: "Sobradinho II", valor: "Sobradinho II" },
    { chave: "Área de Desenvolvimento Econômico", valor: "Área de Desenvolvimento Econômico" },
    { chave: "Itapoã", valor: "Itapoã" },
    { chave: "Águas Claras", valor: "Águas Claras" },
    { chave: "Paranoá", valor: "Paranoá" },
    { chave: "Fercal", valor: "Fercal" },
    { chave: "Lago Sul", valor: "Lago Sul" },
    { chave: "Riacho Fundo II", valor: "Riacho Fundo II" },
    { chave: "Lago Norte", valor: "Lago Norte" },
    { chave: "Park Way", valor: "Park Way" },
    { chave: "Candangolândia", valor: "Candangolândia" },
    { chave: "Guará", valor: "Guará" },
    { chave: "SCIA", valor: "SCIA" },
    { chave: "SIA", valor: "SIA" },
    { chave: "Setor de Indústrias", valor: "Setor de Indústrias" },
    { chave: "Sobradinho II", valor: "Sobradinho II" },
    { chave: "Sol Nascente/Pôr do Sol", valor: "Sol Nascente/Pôr do Sol" },
    { chave: "Jardins Mangueiral", valor: "Jardins Mangueiral" },
    { chave: "Vicente Pires", valor: "Vicente Pires" },
    { chave: "Santa Maria", valor: "Santa Maria" },
    { chave: "Setor Complementar de Indústria e Abastecimento", valor: "Setor Complementar de Indústria e Abastecimento" },
  ];
  
  export default cidades;
  