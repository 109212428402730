const modelosFord = [
  { chave: "", valor: "" },
  { chave: "ka", valor: "Ka" },
  { chave: "fiesta", valor: "Fiesta" },
  { chave: "ecosport", valor: "EcoSport" },
  { chave: "focus", valor: "Focus" },
  { chave: "fusion", valor: "Fusion" },
  { chave: "ranger", valor: "Ranger" },
  { chave: "territory", valor: "Territory" },
  { chave: "edge", valor: "Edge" },
  { chave: "explorer", valor: "Explorer" },
  { chave: "f-150", valor: "F-150" },
  { chave: "mustang", valor: "Mustang" },
  { chave: "transit", valor: "Transit" },
  { chave: "mondeo", valor: "Mondeo" },
  { chave: "escort", valor: "Escort" },
  { chave: "ka-sedan", valor: "Ka Sedan" },
  { chave: "new-fiesta", valor: "New Fiesta" },
  { chave: "fiesta-sedan", valor: "Fiesta Sedan" },
  { chave: "focus-hatch", valor: "Focus Hatch" },
  { chave: "focus-sedan", valor: "Focus Sedan" },
  { chave: "fusion-hybrid", valor: "Fusion Hybrid" },
  { chave: "ranger-raptor", valor: "Ranger Raptor" },
  { chave: "ecosport-se", valor: "EcoSport SE" },
  { chave: "territory-se", valor: "Territory SE" },
  { chave: "edge-st", valor: "Edge ST" },
  { chave: "explorer-limited", valor: "Explorer Limited" },
  { chave: "f-150-lariat", valor: "F-150 Lariat" },
  { chave: "mustang-gt", valor: "Mustang GT" },
  { chave: "transit-passenger", valor: "Transit Passenger" },
  { chave: "mondeo-vignale", valor: "Mondeo Vignale" },
  { chave: "escort-ghia", valor: "Escort Ghia" },
  { chave: "ka-plus", valor: "Ka Plus" },
  { chave: "fiesta-st", valor: "Fiesta ST" },
  { chave: "ecosport-freestyle", valor: "EcoSport Freestyle" },
  { chave: "focus-titanium", valor: "Focus Titanium" },
  { chave: "fusion-sel", valor: "Fusion SEL" },
  { chave: "ranger-xlt", valor: "Ranger XLT" },
  { chave: "territory-titanium", valor: "Territory Titanium" },
  { chave: "edge-titanium", valor: "Edge Titanium" },
  { chave: "explorer-platinum", valor: "Explorer Platinum" },
  { chave: "f-150-king-ranch", valor: "F-150 King Ranch" },
  { chave: "mustang-shelby-gt500", valor: "Mustang Shelby GT500" },
  { chave: "transit-courier", valor: "Transit Courier" },
  { chave: "mondeo-titanium", valor: "Mondeo Titanium" },
  { chave: "escort-xr3", valor: "Escort XR3" },
];

export default modelosFord;
