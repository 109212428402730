<template>
  <div>
    <Conteudo v-model="nomeDaRota"></Conteudo>
  </div>
</template>
<script>
import Conteudo from "@/components/Conteudo.vue";
export default {
  data() {
    return {
      nomeDaRota: "",
    };
  },
  watch: {
    "$route.name": "atualizarNomeDaRota",
  },
  components: {
    Conteudo,
  },

  methods: {
    atualizarNomeDaRota() {
      this.nomeDaRota = this.$route.name;
    },
  },

  created() {
    this.atualizarNomeDaRota();
  },
};
</script>

<style scoped>
.img {
  margin: auto;
  height: 560px !important;
}
</style>
