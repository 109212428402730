const modelosFiat = [
  { chave: "", valor: "" },
  { chave: "argo", valor: "Argo" },
  { chave: "uno", valor: "Uno" },
  { chave: "mobi", valor: "Mobi" },
  { chave: "toro", valor: "Toro" },
  { chave: "strada", valor: "Strada" },
  { chave: "palio", valor: "Palio" },
  { chave: "cronos", valor: "Cronos" },
  { chave: "siena", valor: "Siena" },
  { chave: "fiorino", valor: "Fiorino" },
  { chave: "ducato", valor: "Ducato" },
  { chave: "grand-siena", valor: "Grand Siena" },
  { chave: "doblo", valor: "Doblò" },
  { chave: "weekend", valor: "Weekend" },
  { chave: "500", valor: "500" },
  { chave: "linea", valor: "Linea" },
  { chave: "argo-trekking", valor: "Argo Trekking" },
  { chave: "toro-ranch", valor: "Toro Ranch" },
  { chave: "strada-adventure", valor: "Strada Adventure" },
  { chave: "strada-hard-working", valor: "Strada Hard Working" },
  { chave: "uno-way", valor: "Uno Way" },
  { chave: "mobi-way", valor: "Mobi Way" },
  { chave: "palio-weekend", valor: "Palio Weekend" },
  { chave: "fiorino-ambulancia", valor: "Fiorino Ambulância" },
  { chave: "ducato-van", valor: "Ducato Van" },
  { chave: "argo-hgt", valor: "Argo HGT" },
  { chave: "toro-volcano", valor: "Toro Volcano" },
  { chave: "strada-trekking", valor: "Strada Trekking" },
  { chave: "palio-attractive", valor: "Palio Attractive" },
  { chave: "cronos-drive", valor: "Cronos Drive" },
  { chave: "uno-evo", valor: "Uno Evo" },
  { chave: "mobi-like", valor: "Mobi Like" },
  { chave: "linea-essence", valor: "Linea Essence" },
  { chave: "doblo-adventure", valor: "Doblò Adventure" },
  { chave: "weekend-adventure", valor: "Weekend Adventure" },
  { chave: "500-cult", valor: "500 Cult" },
  { chave: "strada-working", valor: "Strada Working" },
  { chave: "toro-freedom", valor: "Toro Freedom" },
  { chave: "palio-sporting", valor: "Palio Sporting" },
  { chave: "ducato-minibus", valor: "Ducato Minibus" },
  { chave: "argo-turbo", valor: "Argo Turbo" },
  { chave: "toro-endurance", valor: "Toro Endurance" },
  { chave: "strada-fire", valor: "Strada Fire" },
  { chave: "palio-blackmotion", valor: "Palio Blackmotion" },
  { chave: "doblo-essence", valor: "Doblò Essence" },
  { chave: "500-abarth", valor: "500 Abarth" },
];

export default modelosFiat;
