const modelosNissan = [
  { chave: "", valor: "" },
  { chave: "march", valor: "March" },
  { chave: "versa", valor: "Versa" },
  { chave: "sentra", valor: "Sentra" },
  { chave: "kicks", valor: "Kicks" },
  { chave: "rogue", valor: "Rogue" },
  { chave: "x-trail", valor: "X-Trail" },
  { chave: "frontier", valor: "Frontier" },
  { chave: "titan", valor: "Titan" },
  { chave: "altima", valor: "Altima" },
  { chave: "maxima", valor: "Maxima" },
  { chave: "370z", valor: "370Z" },
  { chave: "armada", valor: "Armada" },
  { chave: "murano", valor: "Murano" },
  { chave: "pathfinder", valor: "Pathfinder" },
  { chave: "leaf", valor: "Leaf" },
  { chave: "juke", valor: "Juke" },
  { chave: "qashqai", valor: "Qashqai" },
  { chave: "micra", valor: "Micra" },
  { chave: "tida", valor: "Tiida" },
  { chave: "370z-nismo", valor: "370Z Nismo" },
  { chave: "note", valor: "Note" },
  { chave: "cube", valor: "Cube" },
  { chave: "gt-r", valor: "GT-R" },
  { chave: "nv200", valor: "NV200" },
  { chave: "nv-passenger", valor: "NV Passenger" },
  { chave: "nv-cargo", valor: "NV Cargo" },
  { chave: "nv200-compact-cargo", valor: "NV200 Compact Cargo" },
  { chave: "roguue-sport", valor: "Rogue Sport" },
  { chave: "sentra-sr", valor: "Sentra SR" },
  { chave: "kicks-sv", valor: "Kicks SV" },
  { chave: "frontier-le", valor: "Frontier LE" },
  { chave: "altima-sv", valor: "Altima SV" },
  { chave: "maxima-platinum", valor: "Maxima Platinum" },
  { chave: "armada-sv", valor: "Armada SV" },
  { chave: "murano-s", valor: "Murano S" },
  { chave: "pathfinder-s", valor: "Pathfinder S" },
  { chave: "juke-s", valor: "Juke S" },
  { chave: "qashqai-s", valor: "Qashqai S" },
  { chave: "micra-s", valor: "Micra S" },
  { chave: "tida-s", valor: "Tiida S" },
  { chave: "370z-nismo", valor: "370Z Nismo" },
  { chave: "note-s", valor: "Note S" },
  { chave: "cube-s", valor: "Cube S" },
  { chave: "gt-r-nismo", valor: "GT-R Nismo" },
  { chave: "nv200-s", valor: "NV200 S" },
  { chave: "nv-passenger-s", valor: "NV Passenger S" },
  { chave: "nv-cargo-s", valor: "NV Cargo S" },
  { chave: "nv200-compact-cargo-s", valor: "NV200 Compact Cargo S" },
  { chave: "roguue-sport-s", valor: "Rogue Sport S" },
];

export default modelosNissan;
