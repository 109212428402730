<template>
  <v-app class="app">
    <!-- Carrossel principal -->
    <div style="width: 100%">
      <v-carousel
        class="carousel"
        ref="carousel"
        hide-delimiters
        :transition="fadeTransition"
        :cycle="1"
        :max="1"
        v-if="car && car.Fotos.length > 0 && !isMobile"
        :cols="isMobile ? 1 : 3"
        :items-per-page="1"
        
      >
        <v-carousel-item
          v-for="(fotoGroup, index) in chunkedFotos"
          :key="index"
          fade
        >
          <v-row>
            <v-col
              v-for="(fotoItem, colIndex) in fotoGroup"
              :key="colIndex"
              :cols="isMobile ? 12 : 4"
            >
              <v-img lazy-src class="img" :src="fotoItem" alt="Imagem do Carro"></v-img>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>

      <!-- Carrossel adicional para telas menores (mostrando apenas uma imagem) -->
      <div v-else-if="car && car.Fotos.length > 0">
        <v-row style="width: 100%; margin: auto">
          <v-col v-for="(foto, index) in car.Fotos" :key="index" :cols="12">
            <v-img lazy-src class="img" :src="foto" alt="Imagem do Carro"></v-img>
          </v-col>
        </v-row>
      </div>

      <!-- Informações do carro dentro de um card -->

      <div v-else>
        <p>Carro não encontrado.</p>
      </div>

      <!-- Informações do carro dentro de um card -->

      <v-card class="card-1">
        <div class="container-info">
          <h2>
            {{ car.Marca?.toUpperCase() }}
            <span style="font-size: 35px; color: black">{{
              car.Modelo?.toUpperCase()
            }}</span>
          </h2>
          <h2 class="ml-auto">
            {{ formatarBRL(car.Preco) || "Sem informação" }}
          </h2>
        </div>
        <h4 class="versao">{{ car.Versao?.toUpperCase() }}</h4>
      </v-card>

      <v-card v-if="car" class="car-info">
        <div v-if="car?.Descricao">
          <p class="p-card">Sobre este veículo</p>
          <p>{{ car.Descricao }}</p>
          <hr />
        </div>

        <p class="p-card">Informações do Veículo</p>
        <v-row>
          <v-col cols="6" md="3">
            <span>Ano</span>
            <h3>{{ car.Ano || "Sem informação" }}</h3>
          </v-col>
          <v-col cols="6" md="3">
            <span>Câmbio</span>
            <h3>{{ car.Cambio || "Sem informação" }}</h3>
          </v-col>
          <v-col cols="6" md="3">
            <span>Cor</span>
            <h3>{{ car.Cor || "Sem informação" }}</h3>
          </v-col>
          <v-col cols="6" md="3">
            <span>Único Dono</span>
            <h3>{{ car.UnicoDono ? "Sim" : "Não" || "Sem informação" }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <span>Direção</span>
            <h3>{{ car.Direcao || "Sem informação" }}</h3>
            <!-- <span>Carroceria:</span>
            <h3>{{ car.Carroceria || "Sem informação" }}</h3> -->
          </v-col>
          <v-col cols="6" md="3">
            <span>Quilometragem</span>
            <h3>
              {{ validarQuilometragem(car.Quilometragem) || "Sem informação" }}
            </h3>
          </v-col>
          <v-col cols="6" md="3">
            <span>Combustível</span>
            <h3>{{ car.Combustivel || "Sem informação" }}</h3>
          </v-col>
          <v-col cols="6" md="3">
            <span>Aceita Troca</span>
            <h3>{{ car.AceitaTroca ? "Sim" : "Não" || "Sem informação" }}</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" md="3">
            <span>Chave Reserva</span>
            <h3>{{ car.ChaveReserva ? "Sim" : "Não" }}</h3>
          </v-col>
          <v-col cols="6" md="3">
            <span>Manual do Proprietário</span>
            <h3>{{ car.Manual ? "Sim" : "Não" }}</h3>
          </v-col>
        </v-row>
      </v-card>
      <div v-else>
        <p>Carro não encontrado.</p>
      </div>

      <v-card class="card-2">
        <p>Opcionais</p>
        <v-row>
          <v-col
            v-for="(opcional, index) in car.Opcionais"
            :key="index"
            :cols="isMobile ? '6' : '12'"
            sm="6"
            md="4"
            lg="3"
          >
            <span>{{ opcional }}</span>
          </v-col>
        </v-row>
      </v-card>

      <div class="bg">
        <h2 class="mb-8">Entrar em contato</h2>
        <v-form ref="form">
          <v-row>
            <!-- Coluna 1 - Nome -->
            <v-col cols="12" sm="4">
              <v-text-field
                class="textfield"
                v-model="inputData.Nome"
                :rules="[rules.required]"
                dense
                outlined
                label="Nome"
              ></v-text-field>
            </v-col>

            <!-- Coluna 2 - Telefone -->
            <v-col cols="12" sm="4">
              <v-text-field
                class="textfield"
                v-mask="'(##) #####-####'"
                validate-on-blur
                v-model="inputData.Telefone"
                :rules="[rules.telefone]"
                dense
                outlined
                label="Telefone"
              ></v-text-field>
            </v-col>

            <!-- Coluna 3 - Email -->
            <v-col cols="12" sm="4">
              <v-text-field
                class="textfield"
                v-model="inputData.Email"
                :rules="[rules.email]"
                dense
                outlined
                label="Email"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-textarea
                label="Mensagem"
                v-model="message"
                :rules="[rules.required]"
                dense
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>

        <div class="d-flex">
          <v-btn
            aria-label="Enviar"
            class="ml-auto botao"
            color="#D4A160"
            :loading="loading"
            @click="sendEmail()"
            >Enviar</v-btn
          >
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { db } from "@/firebase.js";
import { mask } from "vue-the-mask";
import emailjs from "emailjs-com";
import { mapActions } from "vuex";
import { doc, getDoc } from "firebase/firestore";
import { CapitalizacaoMixin } from "@/mixins/capitalizacaoMixin";
import { BRL } from "@/mixins/BRL";
import { Quilometragem } from "@/mixins/Quilometragem";
import sendEmail from "../../sendEmail";
export default {
  mixins: [CapitalizacaoMixin, BRL, Quilometragem],
  directives: { mask },

  data() {
    return {
      inputData: {
        Mensagem: null,
      },
      message: "Olá, tenho interesse no veículo. Por favor entre em contato.",

      rules: {
        required: (value) => !!value || "Campo obrigatório",
        email: (value) => {
          if (!value) return "Campo obrigatório";
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "O e-mail informado é inválido.";
        },
        telefone: (value) => {
          if (!value) return "Campo obrigatório";
          else if (value) {
            const pattern =
              /^\(([1-9]{2})\) (?:[2-8]|9[1-9])[0-9]{4}-[0-9]{4}$/;
            const pattern2 = /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/;
            return (
              pattern.test(value) ||
              pattern2.test(value) ||
              "O telefone informado é inválido"
            );
          } else return null;
        },
      },
      fadeTransition: "fade-transition",
      car: {
        Marca: "",
        Fotos: [],
      },
      selectedThumbnail: 0,
      isMobile: false,
      loading: false,
    };
  },
  computed: {
    chunkedFotos() {
      return this.car.Fotos.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 3);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
    },
  },
  async created() {
    const carId = this.$route.params.id;
    const carDoc = doc(db, "cars", carId);
    const carSnapshot = await getDoc(carDoc);

    if (carSnapshot.exists()) {
      this.car = { id: carSnapshot.id, ...carSnapshot.data() };
    }

    window.addEventListener("resize", this.checkIsMobile);
    this.checkIsMobile();

    if (!this.isMobile)
    window.scrollTo({
      top: 770,
      behavior: "smooth",
    });
  },

  methods: {
    ...mapActions("EmailSender", ["email"]),

    sendEmail() {
      this.inputData.Carro = this.car?.Marca + " " + this.car?.Modelo;
      this.inputData.Placa = this.car?.Placa;
      this.inputData.Preco = this.car?.Preco;

      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.inputData.Mensagem = this.message;

      try {
        emailjs
          .send("service_5xam0yb", "template_42xnyjm", this.inputData)
          .then((r) => {
            {
              this.loading = false;
              return alert("E-mail enviado com sucesso!");
            }
          });
      } catch (error) {
        this.loading = false;
        return alert("Erro ao enviar, tente novamente mais tarde!");
      }
    },

    selectThumbnail(index) {
      this.selectedThumbnail = index;
      this.$refs.carousel.value = index;
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 900;
    },
    getRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
hr {
  border: 0.1px solid rgba(152, 152, 152, 0.2);
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
}

.botao {
  background-color: #ea4f4f !important;
  color: white !important;
  border-radius: 10px;
  font-size: 16px !important;
  font-weight: bold;
  width: 150px;
  height: 50px !important ;
}
.v-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.02) !important;
}
.bg {
  background-color: white;
  width: 80%;
  margin: auto;
  margin-top: 60px;
  padding: 70px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.02) !important;
}

.bg .v-text-field {
  border-radius: 15px;
  color: black;
  background-color: white !important;
}

.container-info {
  display: flex;
}
.app {
  margin-top: 200px;
}
.img-container {
  width: 100%;
  text-align: center;
}
.versao {
  color: gray;
  font-weight: normal;
}

.p-card {
  font-weight: bold;
  font-size: 24px;
  color: black;
  margin-bottom: 20px !important;
}
.img {
  width: auto;
  border-radius: 20px;
  height: 400px;
  object-fit: fill;
  margin: 0 auto;
}

.card-1 {
  margin-bottom: 20px !important;
  margin-top: -150px !important;
  padding: 70px;
  width: 80%;
  margin: auto;
  border-radius: 30px !important;
  height: 200px;
}

.car-info {
  align-items: center;
  margin: auto;
  text-align: left;
  width: 80%;
  padding: 40px 70px;
  border-radius: 30px !important;
}
.card-2 {
  font-size: 17px;
  font-weight: bold;
  color: gray !important;
  padding: 40px 70px;
  margin-top: 20px !important;
  height: auto;
  width: 80%;
  margin: auto;
  border-radius: 30px !important;
}

.card-2 p {
  font-size: 24px;
  color: black;
  margin-bottom: 20px !important;
}
.card-1 h2 {
  margin-bottom: 10px;
  font-size: 35px;
}

.no-margin {
  margin: 0;
}

span {
  font-size: 22px;
  color: gray;
}

@media (max-width: 850px) {
  .bg {
    background-color: white;
    width: 90%;
    margin: auto;
    margin-top: 60px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.02) !important;
  }
  .card-1 {
    padding: 30px !important;
    width: 90%;
    margin-top: 10px !important;
    margin-bottom: 10px;
    font-size: 15px !important;
  }

  .container-info h2 {
    text-align: left !important;
  }

  .versao {
    margin: auto;
    text-align: left;
  }

  .container-info {
    display: block;
  }

  .container-info h2,
  span {
    font-size: 25px !important;
  }

  .card-2 {
    font-size: 17px;
    font-weight: bold;
    color: gray !important;
    padding: 20px 30px;
    margin-top: 20px !important;
    height: auto;
    width: 90% !important;
    margin: auto;
    border-radius: 30px !important;
  }

  .car-info {
    padding: 30px;
    margin-top: 20px;
    width: 90%;
    font-size: 15px;
  }

  .car-info span {
    font-size: 15px !important;
  }

  .p-card {
    font-size: 20px;
  }

  .app {
    margin-top: 100px;
  }

  .img {
    width: 100%;
    object-fit: fill;
    margin: 0 auto;
    height: auto;
  }
  .card-2 span {
    font-size: 14px !important;
  }
}
</style>
