<template>
    <div fluid class="login-container">
      <v-row justify="center" class="login-row">
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-card>
            <v-card-title class="text-center">
              <h2>Login</h2>
            </v-card-title>
            <v-card-text>
              <form @submit.prevent="signIn">
                <v-text-field
                  v-model="email"
                  label="Email"
                  type="email"
                  required
                  outlined
                  class="input-field"
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  label="Senha"
                  type="password"
                  required
                  outlined
                  class="input-field"
                ></v-text-field>

                <v-btn  aria-label="Entrar" type="submit" class="primary">Entrar</v-btn>
                <!-- <button @click="signInWithGoogle()">Google</button> -->
              </form>
              <p v-if="error" class="error">{{ error }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import router from "@/router";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

export default {
  data() {
    return {
      email: "",
      password: "",
      error: null,
    };
  },
  methods: {
    signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      signInWithPopup(getAuth(), provider)
        .then((result) => {
          router.push("/create");
        })
        .catch((error) => {});
    },
    signIn() {
      signInWithEmailAndPassword(getAuth(), this.email, this.password)
        .then((userCredential) => {
          // Usuário logado com sucesso
          const user = userCredential.user;
          router.push("/create");
        })
        .catch((error) => {
          // Ocorreu um erro durante o login
          this.error = error.message;
        });
    },
  },
};
</script>

<style scoped>
/* Adicione estilos adicionais aqui, se necessário */

.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-row {
  margin-top: 40px; /* Ajuste conforme necessário */
}

.input-field {
  width: 100%;
}

.primary {
  background-color: #4caf50;
  color: white;
}

.error {
  color: red;
}
</style>
