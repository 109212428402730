const modelosAudi = [
  { chave: "", valor: "" },
  { chave: "a1", valor: "A1" },
  { chave: "a3", valor: "A3" },
  { chave: "a4", valor: "A4" },
  { chave: "a5", valor: "A5" },
  { chave: "a6", valor: "A6" },
  { chave: "a7", valor: "A7" },
  { chave: "a8", valor: "A8" },
  { chave: "q2", valor: "Q2" },
  { chave: "q3", valor: "Q3" },
  { chave: "q4", valor: "Q4" },
  { chave: "q5", valor: "Q5" },
  { chave: "q7", valor: "Q7" },
  { chave: "q8", valor: "Q8" },
  { chave: "e-tron", valor: "e-tron" },
  { chave: "tt", valor: "TT" },
  { chave: "r8", valor: "R8" },
  { chave: "s1", valor: "S1" },
  { chave: "s3", valor: "S3" },
  { chave: "s4", valor: "S4" },
  { chave: "s5", valor: "S5" },
  { chave: "s6", valor: "S6" },
  { chave: "s7", valor: "S7" },
  { chave: "s8", valor: "S8" },
  { chave: "sq2", valor: "SQ2" },
  { chave: "sq3", valor: "SQ3" },
  { chave: "sq4", valor: "SQ4" },
  { chave: "sq5", valor: "SQ5" },
  { chave: "sq7", valor: "SQ7" },
  { chave: "sq8", valor: "SQ8" },
  { chave: "rs3", valor: "RS3" },
  { chave: "rs4", valor: "RS4" },
  { chave: "rs5", valor: "RS5" },
  { chave: "rs6", valor: "RS6" },
  { chave: "rs7", valor: "RS7" },
  { chave: "rsq3", valor: "RS Q3" },
  { chave: "rsq4", valor: "RS Q4" },
  { chave: "r8", valor: "R8" },
  { chave: "s3-sportback", valor: "S3 Sportback" },
  { chave: "s4-avant", valor: "S4 Avant" },
  { chave: "s5-sportback", valor: "S5 Sportback" },
  { chave: "s6-avant", valor: "S6 Avant" },
  { chave: "s7-sportback", valor: "S7 Sportback" },
  { chave: "s8", valor: "S8" },
  { chave: "sq5-sportback", valor: "SQ5 Sportback" },
  { chave: "sq7", valor: "SQ7" },
  { chave: "sq8", valor: "SQ8" },
  { chave: "rs3-sportback", valor: "RS3 Sportback" },
  { chave: "rs4-avant", valor: "RS4 Avant" },
  { chave: "rs5-sportback", valor: "RS5 Sportback" },
  { chave: "rs6-avant", valor: "RS6 Avant" },
  { chave: "rs7-sportback", valor: "RS7 Sportback" },
  { chave: "rsq3", valor: "RS Q3" },
  { chave: "rsq4-sportback", valor: "RS Q4 Sportback" },
  { chave: "r8", valor: "R8" },
  { chave: "s3-sportback", valor: "S3 Sportback" },
];

export default modelosAudi;
