import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBlSCcCLaP13xlFtNYvGX9jLtWv0pTekPM",
  authDomain: "carros-eab97.firebaseapp.com",
  projectId: "carros-eab97",
  storageBucket: "carros-eab97.appspot.com",
  messagingSenderId: "614956995087",
  appId: "1:614956995087:web:d97ff0a4a5de6d45ba097a",
  measurementId: "G-0TCS4CPWVS",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { app, db, auth };
