const modelosChevrolet = [
  { chave: "", valor: "" },
  { chave: "onix", valor: "Onix" },
  { chave: "prisma", valor: "Prisma" },
  { chave: "joy", valor: "Joy" },
  { chave: "tracker", valor: "Tracker" },
  { chave: "spin", valor: "Spin" },
  { chave: "s10", valor: "S10" },
  { chave: "equinox", valor: "Equinox" },
  { chave: "cruze", valor: "Cruze" },
  { chave: "cobalt", valor: "Cobalt" },
  { chave: "camaro", valor: "Camaro" },
  { chave: "montana", valor: "Montana" },
  { chave: "blazer", valor: "Blazer" },
  { chave: "trailblazer", valor: "Trailblazer" },
  { chave: "malibu", valor: "Malibu" },
  { chave: "silverado", valor: "Silverado" },
  { chave: "sonic", valor: "Sonic" },
  { chave: "agile", valor: "Agile" },
  { chave: "captiva", valor: "Captiva" },
  { chave: "cruze-sport6", valor: "Cruze Sport6" },
  { chave: "celta", valor: "Celta" },
  { chave: "astra", valor: "Astra" },
  { chave: "corsa", valor: "Corsa" },
  { chave: "celta", valor: "Celta" },
  { chave: "classic", valor: "Classic" },
  { chave: "cavalier", valor: "Cavalier" },
  { chave: "agile", valor: "Agile" },
  { chave: "joy-plus", valor: "Joy Plus" },
  { chave: "spin-activ", valor: "Spin Activ" },
  { chave: "s10-high-country", valor: "S10 High Country" },
  { chave: "equinox-premier", valor: "Equinox Premier" },
  { chave: "cruze-lt", valor: "Cruze LT" },
  { chave: "cobalt-elite", valor: "Cobalt Elite" },
  { chave: "camaro-ss", valor: "Camaro SS" },
  { chave: "montana-conquest", valor: "Montana Conquest" },
  { chave: "blazer-premier", valor: "Blazer Premier" },
  { chave: "trailblazer-premier", valor: "Trailblazer Premier" },
  { chave: "malibu-ltz", valor: "Malibu LTZ" },
  { chave: "silverado-high-country", valor: "Silverado High Country" },
  { chave: "sonic-lt", valor: "Sonic LT" },
  { chave: "agile-ltz", valor: "Agile LTZ" },
  { chave: "captiva-ltz", valor: "Captiva LTZ" },
  { chave: "cruze-sport6-ltz", valor: "Cruze Sport6 LTZ" },
  { chave: "celta-life", valor: "Celta Life" },
  { chave: "astra-sedan", valor: "Astra Sedan" },
  { chave: "corsa-wind", valor: "Corsa Wind" },
  { chave: "classic-ltz", valor: "Classic LTZ" },
  { chave: "cavalier-premier", valor: "Cavalier Premier" },
];

export default modelosChevrolet;
