export const BRL = {
  methods: {
    formatarBRL(valor) {
      const numeroFormatado = parseFloat(valor).toFixed(2);
      const partes = numeroFormatado.split(".");
      const parteInteira = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      const resultado = `${parteInteira},${partes[1]}`;
      return `R$ ${resultado}`;
    },
  },
};
