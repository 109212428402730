const modelosToyota = [
  { chave: "", valor: "" },
  { chave: "camry", valor: "Camry" },
  { chave: "corolla", valor: "Corolla" },
  { chave: "prius", valor: "Prius" },
  { chave: "rav4", valor: "RAV4" },
  { chave: "hilux", valor: "Hilux" },
  { chave: "etios", valor: "Etios" },
  { chave: "sw4", valor: "SW4" },
  { chave: "corolla-cross", valor: "Corolla Cross" },
  { chave: "yaris", valor: "Yaris" },
  { chave: "c-hr", valor: "C-HR" },
  { chave: "sequoia", valor: "Sequoia" },
  { chave: "tundra", valor: "Tundra" },
  { chave: "supra", valor: "Supra" },
  { chave: "venza", valor: "Venza" },
  { chave: "prius-c", valor: "Prius c" },
  { chave: "highlander", valor: "Highlander" },
  { chave: "tacoma", valor: "Tacoma" },
  { chave: "4runner", valor: "4Runner" },
  { chave: "avalon", valor: "Avalon" },
  { chave: "landcruiser", valor: "Land Cruiser" },
  { chave: "sienna", valor: "Sienna" },
  { chave: "yaris-cross", valor: "Yaris Cross" },
  { chave: "landcruiser-prado", valor: "Land Cruiser Prado" },
  { chave: "prius-prime", valor: "Prius Prime" },
  { chave: "mirai", valor: "Mirai" },
  { chave: "corolla-gr-sport", valor: "Corolla GR Sport" },
  { chave: "landcruiser-70", valor: "Land Cruiser 70" },
  { chave: "prius-v", valor: "Prius v" },
  { chave: "corolla-wild-track", valor: "Corolla Wild Track" },
  { chave: "corolla-fielder", valor: "Corolla Fielder" },
  { chave: "tacoma-trd-pro", valor: "Tacoma TRD Pro" },
  { chave: "camry-trd", valor: "Camry TRD" },
  { chave: "corolla-le", valor: "Corolla LE" },
  { chave: "corolla-xle", valor: "Corolla XLE" },
  { chave: "corolla-xse", valor: "Corolla XSE" },
  { chave: "highlander-hybrid", valor: "Highlander Hybrid" },
  { chave: "landcruiser-200", valor: "Land Cruiser 200" },
  { chave: "landcruiser-200-prime", valor: "Land Cruiser 200 Prime" },
  { chave: "tacoma-sr", valor: "Tacoma SR" },
  { chave: "tacoma-limited", valor: "Tacoma Limited" },
  { chave: "4runner-trd-pro", valor: "4Runner TRD Pro" },
  { chave: "4runner-limited", valor: "4Runner Limited" },
  { chave: "avalon-limited", valor: "Avalon Limited" },
  { chave: "avalon-xse", valor: "Avalon XSE" },
  { chave: "sienna-limited", valor: "Sienna Limited" },
  { chave: "sienna-xse", valor: "Sienna XSE" },
];

export default modelosToyota;
