const tiposCombustivel = [
  { chave: "Gasolina", valor: "Gasolina" },
  { chave: "Álcool (Etanol)", valor: "Álcool (Etanol)" },
  { chave: "Diesel", valor: "Diesel" },
  { chave: "Gás Natural", valor: "Gás Natural" },
  { chave: "Flex", valor: "Flex" },
  { chave: "Híbrido", valor: "Híbrido" },
  { chave: "Elétrico", valor: "Elétrico" },
  { chave: "Hidrogênio", valor: "Hidrogênio" },
  { chave: "Biodiesel", valor: "Biodiesel" },
  { chave: "Diesel Sintético", valor: "Diesel Sintético" },
  { chave: "Outro", valor: "Outro" },
];

export default tiposCombustivel;
