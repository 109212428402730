<template>
  <div class="containerr">
    <v-row class="cards">
      <v-col
        v-for="car in cars"
        :key="car.id"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="padding-card"
      >
        <v-card class="car-card" @click="goToViewPage(car.id)">
          <v-img
            alt="Imagem do Carro"
            lazy-src
            :src="car.downloadURL"
            aspect-ratio="2.5"
            class="car-image"
          ></v-img>

          <div class="card-padding mb-2">
            <h5>
              {{ car.Marca.toUpperCase() }}
              <span class="red-text">{{ car.Modelo.toUpperCase() }}</span>
            </h5>
            <p class="text">
              {{ car.Versao.toUpperCase() }}
            </p>
            <span class="text">{{ car.Cambio.toUpperCase() }}</span>
          </div>
          <hr />

          <div class="card-padding">
            <h2>{{ formatarBRL(car.Preco) }}</h2>
            <div class="d-flex mt-1">
              <span class="descricao text">
                {{ car.Ano + " / " + car.AnoModelo }}
              </span>
              <p class="ml-auto descricao text">
                {{ validarQuilometragem(car.Quilometragem) }}
              </p>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { db, storage } from "@/firebase.js";
import { collection, onSnapshot } from "firebase/firestore";
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { CapitalizacaoMixin } from "../mixins/capitalizacaoMixin";
import { BRL } from "../mixins/BRL";
import { Quilometragem } from "../mixins/Quilometragem";
import listaDirecao from "../listas/direcao";
export default {
  mixins: [CapitalizacaoMixin, BRL, Quilometragem],
  data: () => ({
    cars: [],
  }),

  methods: {
    goToViewPage(carId) {
      this.$router.push({ name: "editar-carro", params: { id: carId } });
    },
  },

  mounted() {
    const carsCollection = collection(db, "cars");

    onSnapshot(carsCollection, async (snapshot) => {
      const storage = getStorage();
      this.cars = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const carData = {
            id: doc.id,
            ...doc.data(),
          };

          if (carData.Fotos && carData.Fotos.length > 0) {
            const storageRef = ref(storage, carData.Fotos[0]);
            carData.downloadURL = await getDownloadURL(storageRef);
          }

          return carData;
        })
      );
    });
  },
};
</script>

<style scoped>
.car-card {
  height: 430px;
}

.car-image {
  height: 60% !important;
  object-position: center bottom;
}

.card-padding {
  padding: 10px 10px 0px 10px;
}

.descricao {
  font-size: 13px;
}

.text {
  color: gray;
  font-size: 13px;
}
.containerr {
  width: 80% !important;
  display: block;
  margin: auto;
  margin-top: 20px;
}

.red-text {
  color: red;
}

hr {
  border: 0.1px solid rgba(152, 152, 152, 0.1);
}

.v-select,
.v-text-field {
}

.filter {
  background-color: #f0f0f0; /* Cor de fundo desejada */
  border-radius: 5px; /* Borda arredondada opcional */
  padding: 20px; /* Espaçamento interno opcional */
  padding-bottom: 35px;
  border: 0.1px solid rgba(34, 33, 33, 0.1);
}

.filter-field {
  background-color: #ffffff; /* Cor de fundo para os campos */
}

.containerr {
  width: 80% !important;
  display: block;
  margin: auto;
  margin-top: 20px;
}
.colums {
  width: 100%;
}

.btn {
  padding: 20px;
}

.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.filter2 {
  width: 100%;
  display: flex;
}
@media (max-width: 768px) {
  /* Quando a largura da tela for 768 pixels ou menos */
  .containerr {
    width: 100% !important;
  }

  .colums {
    width: 100%;
    padding: 10px;
  }

  .botao {
    width: 100%;
  }

  .search-button {
    width: 100% !important;
    background-color: white;
  }

  .cards {
    margin: auto;
    width: 100%;
    padding: 20px;
  }

  .padding-card {
    padding: 10px;
  }
}
</style>
