import axios from 'axios';
axios.defaults.baseURL = 'https://localhost:8080';
export default {
  namespaced: true,
  actions: {
 
    async email (_, payload){
      try {
        const response = await axios.post(`/EmailSender`, payload);
        return response.data
      } catch (error){
        return error.message
      }
    },

  },
};
