const modelosMitsubishi = [
  { chave: "", valor: "" },
  { chave: "lancer", valor: "Lancer" },
  { chave: "outlander", valor: "Outlander" },
  { chave: "pajero", valor: "Pajero" },
  { chave: "eclipse-cross", valor: "Eclipse Cross" },
  { chave: "asx", valor: "ASX" },
  { chave: "l200", valor: "L200" },
  { chave: "triton", valor: "Triton" },
  { chave: "pajero-full", valor: "Pajero Full" },
  { chave: "pajero-sport", valor: "Pajero Sport" },
  { chave: "evoque", valor: "Evoque" },
  { chave: "eclipse", valor: "Eclipse" },
  { chave: "galant", valor: "Galant" },
  { chave: "mirage", valor: "Mirage" },
  { chave: "outlander-phev", valor: "Outlander PHEV" },
  { chave: "ralliart", valor: "Ralliart" },
  { chave: "space-star", valor: "Space Star" },
  { chave: "grand-lancer", valor: "Grand Lancer" },
  { chave: "montero", valor: "Montero" },
  { chave: "montero-sport", valor: "Montero Sport" },
  { chave: "colt", valor: "Colt" },
  { chave: "delica", valor: "Delica" },
  { chave: "eclipse-spyder", valor: "Eclipse Spyder" },
  { chave: "endeavor", valor: "Endeavor" },
  { chave: "fuso", valor: "Fuso" },
  { chave: "gto", valor: "GTO" },
  { chave: "i-miev", valor: "i-MiEV" },
  { chave: "lancer-evolution", valor: "Lancer Evolution" },
  { chave: "minicab", valor: "Minicab" },
  { chave: "minica", valor: "Minica" },
  { chave: "outlander-sport", valor: "Outlander Sport" },
  { chave: "raider", valor: "Raider" },
  { chave: "rvr", valor: "RVR" },
  { chave: "sigma", valor: "Sigma" },
  { chave: "space-gear", valor: "Space Gear" },
  { chave: "space-runner", valor: "Space Runner" },
  { chave: "space-wagon", valor: "Space Wagon" },
  { chave: "starion", valor: "Starion" },
  { chave: "tredia", valor: "Tredia" },
  { chave: "3000gt", valor: "3000GT" },
  { chave: "500", valor: "500" },
  { chave: "attrage", valor: "Attrage" },
  { chave: "grandis", valor: "Grandis" },
  { chave: "l200-sportero", valor: "L200 Sportero" },
  { chave: "mirage-g4", valor: "Mirage G4" },
  { chave: "outlander-xl", valor: "Outlander XL" },
  { chave: "shogun", valor: "Shogun" },
  { chave: "space-star", valor: "Space Star" },
];

export default modelosMitsubishi;
