<template>
  <div></div>
</template>

<script>
export default {
  created() {
    if (this.$route.params) {
      this.search = this.$route.params.search;
      this.$router.push({
        name: "estoque",
        params: { search: this.search },
      });
    }
  },
};
</script>

<style></style>
