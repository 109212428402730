<template>
  <div>
    <!-- Menu celular que abre-->
    <v-navigation-drawer v-if="!isLargeScreen && drawer" v-model="drawer" app>
      <v-btn  aria-label="Logout" text v-if="userLoggedIn" @click="handleSignOut">Logout</v-btn>
      <div class="d-flex menu">
        <h3>Menu</h3>
        <v-btn  aria-label="Fechar" @click.stop="drawer = !drawer" icon class="ml-auto">
          <v-icon>mdi mdi-close</v-icon>
        </v-btn>
      </div>
      <v-list>
        <v-list-item
          v-for="(item, index) in filteredItems"
          :key="index"
          v-if="item.show"
          @click.stop="navigateTo(item.name)"
        >
          <v-list-item-content>
            <div class="d-flex">
              <v-list-item-title class="link">{{
                item.text
              }}</v-list-item-title>
              <v-icon
                v-if="!isLargeScreen"
                @click.stop="drawer = !drawer"
                class="mdi mdi-chevron-right ml-auto icone2"
              ></v-icon>
            </div>
            <hr />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Menu celular  -->
    <v-card
      v-if="!isLargeScreen"
      elevation="4"
      style="
        height: 80px;
        box-shadow: none !important;
        padding: 20px;
        width: 100%;
      "
    >
      <div class="d-flex align-center justify-center" style="height: 100%">
        <div class="imagem-icone">
          <v-icon
            class="mdi mdi-arrow-left mr-auto"
            @click="$router.back()"
          ></v-icon>
        </div>
        <div class="imagem">
          <div>
            <a href="https://lemoscarveiculos.com.br">
              <v-img
              alt="Logo"
                lazy-src
                class="img"
                :width="200"
                src="../assets/LogoLemosCar.png"
              ></v-img>
            </a>
          </div>
        </div>
        <v-icon
          v-if="!isLargeScreen"
          @click.stop="drawer = !drawer"
          class="mdi mdi-menu ml-auto icone"
          style="margin-right: 9px"
        ></v-icon>
      </div>
    </v-card>

    <div v-if="!isLargeScreen">
      <v-img style="max-height: 200px;"  src="@/assets/a.webp" alt="background"> </v-img>

      <div style="margin-top: -50px"  class="card-mobile">
        <div class="text-fields">
          <v-text-field
            class="mx-3"
            hide-datails
            dense
            outlined
            label="Buscar veículo"
            append-icon="mdi mdi-magnify"
            v-model="pesquisar"
            @click:append="sendToEstoque"
            style="border-radius: 10px"
          ></v-text-field>
        </div>
      </div>
    </div>

    <!-- Bacground carro pro pesquisar -->

    <!-- Menu desktop -->
    <v-card v-if="isLargeScreen" class="cardFundo" elevation="0">
      <v-toolbar
        src="../assets/vmww.jpg"
        style="filter: brightness(0.6)"
        :height="isLargeScreen ? '400px' : '150px'"
        color="white"
        dark
        extended
      >
      </v-toolbar>

      <v-card
        class="mx-auto card"
        :max-width="userLoggedIn ? '1200' : '850'"
        width="auto"
      >
        <v-toolbar class="toolbar" flat>
          <a
            v-for="(item, index) in filteredItems"
            :key="index"
            v-if="item.show"
            :href="item.link"
            :class="{ 'active-link': isCurrentRoute(item.name) }"
          >
            {{ item.text }}
          </a>
          <v-btn  aria-label="Logout" text v-if="userLoggedIn" @click="handleSignOut">Logout</v-btn>
        </v-toolbar>
      </v-card>

      <v-card class="infos">
        <div>
          <p class="texto">
            <span class="span">Encontre</span> aqui seu
            <span class="span">carro</span>
          </p>
          <p class="texto"></p>
          <p class="texto">de <span class="span">confiança! </span></p>
        </div>
      </v-card>
      <v-card
        v-if="isLargeScreen"
        :class="isLargeScreen ? 'card2 mx-auto' : 'card2 mx-auto mt-12'"
        max-width="1200"
      >
        <v-toolbar flat>
          <v-icon style="color: black" right>mdi mdi-magnify</v-icon>
          <v-toolbar-title class="font-weight-bold">
            Buscar Veículo
          </v-toolbar-title>
        </v-toolbar>
        <div class="text-fields">
          <v-text-field
            class="mx-10"
            hide-datails
            dense
            outlined
            label="Pesquisar"
            v-model="pesquisar"
            style="border-radius: 20px"
          >
          </v-text-field>
          <v-btn
          aria-label="Pesquisar"
            class="button"
            @click="sendToEstoque"
            style="border-radius: 20px"
            >Pesquisar</v-btn
          >
        </div>
      </v-card>
    </v-card>

    <!-- Buscar veiculo mobile -->
    <!-- <div :class="route != 'estoque' ? 'card-mobile' : 'card-mobile-margin'">
      <v-card
        v-if="route != 'view' && !isLargeScreen"
        :class="!isLargeScreen ? 'card2 mx-auto' : 'card2 mx-auto mt-10'"
        max-width="1200"
      >
        <v-toolbar flat>
          <v-icon right>mdi mdi-magnify</v-icon>
          <v-toolbar-title class="font-weight-bold">
            Buscar Veículo
          </v-toolbar-title>
        </v-toolbar>

        <div class="text-fields">
          <v-form ref="form">
            <v-text-field
              class="mx-3"
              hide-datails
              dense
              outlined
              label="Pesquisar"
              v-model="pesquisar"
              style="border-radius: 20px"
              :rules="[rules.required]"
            ></v-text-field>
            <v-btn
              class="button"
              @click="sendToEstoque"
              style="border-radius: 20px"
              >Pesquisar</v-btn
            >
          </v-form>
        </div>
      </v-card>
    </div> -->
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import router from "@/router";
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },
      isLargeScreen: this.$vuetify.breakpoint.width > 1300,
      drawer: false,
      route: "",
      pesquisar: "",
      batata: "../assets/teste.jpg",
      userLoggedIn: false,
      items: [
        { text: "Início", name: "home", link: "/", show: true },
        { text: "Estoque", name: "estoque", link: "/estoque", show: true },
        {
          text: "Destaques",
          name: "destaques",
          link: "/destaques",
          show: true,
        },
        {
          text: "Mais Procurados",
          name: "mais-procurados",
          link: "/mais-procurados",
          show: true,
        },
        { text: "Sobre Nós", name: "about", link: "/about", show: true },
        { text: "Localização", name: "local", link: "/local", show: true },
        { text: "Novo Carro", name: "create", link: "/create", show: false },
        { text: "Ver Carros", name: "list", link: "/list", show: false },
      ],
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => item.show);
    },
  },
  methods: {
    sendToEstoque() {
      if (this.pesquisar == null || this.pesquisar == "") return;

      if (this.$route.name == "estoque") {
        this.$router.push({
          name: "loading",
          params: { search: this.pesquisar },
        });
      } else {
        this.$router.push({
          name: "estoque",
          params: { search: this.pesquisar },
        });
      }
      this.route = this.$route.name;
      this.pesquisar = "";
    },

    handleSignOut() {
      signOut(getAuth()).then(() => {
        router.push("/");
      });
    },

    navigateTo(name) {
      if (this.$route.name == name) {
        this.drawer = false;
        return;
      }

      if (name == "home") {
        this.$router.push("/");
        return;
      }

      this.$router.push({ name: name });
      this.drawer = false;
    },
    checkUserLoggedIn() {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        this.userLoggedIn = !!user;
        this.updateMenuVisibility();
      });
    },
    updateMenuVisibility() {
      this.items.forEach((item) => {
        if (item.text === "Novo Carro" || item.text === "Ver Carros") {
          item.show = this.userLoggedIn;
        }
      });
    },
    isCurrentRoute(name) {
      return this.$route.name == name;
    },
    atualizarNomeDaRota() {
      this.route = this.$route.name;
    },
  },
  mounted() {
    this.route = this.$route.name;
    this.checkUserLoggedIn();
  },

  watch: {
    "$route.name": "atualizarNomeDaRota",
    "$vuetify.breakpoint.width"() {
      this.isLargeScreen = this.$vuetify.breakpoint.width > 1300;
    },
  },
};
</script>

<style scoped>
.v-card {
  box-shadow: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.02) !important;
}
.link {
  font-size: 18px;
}
hr {
  border: 0.1px solid rgba(152, 152, 152, 0.1);
  margin-top: 5px;
}
.v-navigation-drawer {
  width: 100% !important;
  background-color: #fdfdfd !important;
  padding: 50px;
}
.texto {
  font-weight: 100;
  font-size: 45px;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center !important;
}

.span {
  font-weight: bold;
}

.card2 {
  padding: 20px 40px;
  border-radius: 30px !important;
  margin-top: 100px;
}

.cardFundo {
  background-color: #f5f5f5 !important;
  box-shadow: none !important;
  margin-bottom: 100px;
}

a {
  text-decoration: none;
  font-size: 15px;
  color: black !important;
}

a:hover {
  color: red !important;
}

.card {
  display: flex;
  justify-content: center;
  border-radius: 50px !important;
  margin-top: -400px;
  filter: opacity(0.93);
}

.active-link {
  color: white !important;
  background-color: #ea4f4f;
  border-radius: 50px;
}

.active-link:hover {
  color: white !important;
}
.app-bar {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu {
  padding: 15px;
}

.v-btn.v-btn--active {
  background-color: #ea4f4f !important;
}

.v-app-bar {
  height: 100px !important;
  padding: 15px;
}

.button {
  background-color: #ea4f4f !important;
  color: white !important;
  text-transform: none;
  box-shadow: none;
}

a {
  /* color: white !important; */
  text-decoration: none;
  /* background-color: rgb(69, 69, 69); */
  /* padding: 20px 40px 20px 40px; */
  padding: 10px 30px 10px 30px;
}

a:first-child {
  /* clip-path: polygon(0 100%, 100% 100%, 100% 0, 30% 0); */
  margin-right: -1px;
}

a:last-child {
  /* clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%); */
  margin-left: -1px;
}

.infos {
  margin-top: 80px;
  background-color: transparent !important;
  box-shadow: none !important;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-toolbar {
  display: flex;
  justify-content: center;
}

.text-fields {
  text-align: end;
  display: flex;
}
@media (max-width: 1300px) {
  .imagem {
    display: flex;
    align-items: center;
    margin: auto;
    height: 50px;
    width: 20%;
    margin-right: 120px;
  }

  .imagem-icone {
    width: 30%;
    margin-left: 20px;
  }

  .text-fields {
    display: block;
    width: 100%;
  }
  .img {
    margin-top: -10px;
    width: 120px !important;
    height: 80px !important;
  }
  .infos {
    margin-top: -60px;
    background-color: transparent !important;
    box-shadow: none !important;
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app-bar {
    width: 100% !important;
    display: flex;
    align-items: center;
  }

  .card,
  .toolbar {
    display: flex;
    justify-content: center;
    border-radius: 50px !important;
  }
  .texto {
    font-weight: 100;
    font-size: 15px;
    text-transform: uppercase;
    line-height: 1.2;
    position: relative;
    z-index: 1; /* Add this line to set the z-index */
  }

  .card-mobile {
    position: relative;
    z-index: 1;
    background-color: white;
    padding: 20px 20px 0px 20px;
    margin: auto;
    width: 80% !important;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05) !important;
  }

  .card-mobile-margin {
    margin-top: 20px;
  }

  .card2 {
    justify-content: center;
    margin: auto !important;
  }
  .icone {
    font-size: 40px !important;
  }

  .icone2 {
    font-size: 25px !important;
  }

  .icone:hover {
    color: #ea4f4f !important;
  }

  .button {
    width: 90%;
  }

  .background {
    width: 100%;
    height: 150px;
    background-image: url("../assets/background.jpg");
    background-size: cover; /* Ajuste para 'contain' se preferir que a imagem seja totalmente visível */
    background-position: center;
    background-repeat: no-repeat;
    padding: 100px 20px 0px 20px;
    margin-bottom: 40px !important;
    background-color: white !important;
  }

  @media (max-width: 700px) {
    .card-mobile {
      position: relative;
      background-color: white;
      padding: 20px 20px 0px 20px;
      margin: auto;
      width: 90% !important;
      border-radius: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05) !important;
      z-index: 1; /* Add this line to set the z-index */
    }

    .background {
      padding: 110px 10px 0px 10px;
    }
  }
}
</style>
