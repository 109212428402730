const modelosRenault = [
    { chave: '', valor: '' },
    { chave: 'clio', valor: 'Clio' },
    { chave: 'captur', valor: 'Captur' },
    { chave: 'megane', valor: 'Megane' },
    { chave: 'duster', valor: 'Duster' },
    { chave: 'kwid', valor: 'Kwid' },
    { chave: 'logan', valor: 'Logan' },
    { chave: 'sandero', valor: 'Sandero' },
    { chave: 'talisman', valor: 'Talisman' },
    { chave: 'koleos', valor: 'Koleos' },
    { chave: 'arkana', valor: 'Arkana' },
    { chave: 'twizy', valor: 'Twizy' },
    { chave: 'zoe', valor: 'Zoe' },
    { chave: 'scenic', valor: 'Scenic' },
    { chave: 'latitude', valor: 'Latitude' },
    { chave: 'espace', valor: 'Espace' },
    { chave: 'alaskan', valor: 'Alaskan' },
    { chave: 'wind', valor: 'Wind' },
    { chave: 'fluence', valor: 'Fluence' },
    { chave: 'pulse', valor: 'Pulse' },
    { chave: 'symbol', valor: 'Symbol' },
    { chave: 'avantime', valor: 'Avantime' },
    { chave: 'modus', valor: 'Modus' },
    { chave: 'thalia', valor: 'Thalia' },
    { chave: 'zoe', valor: 'Zoe' },
    { chave: 'twingo', valor: 'Twingo' },
    { chave: 'arkana', valor: 'Arkana' },
    { chave: 'kangoo', valor: 'Kangoo' },
    { chave: 'master', valor: 'Master' },
    { chave: 'trafic', valor: 'Trafic' },
    { chave: 'express', valor: 'Express' },
    { chave: 'safari', valor: 'Safari' },
    { chave: 'super-5', valor: 'Super 5' },
    { chave: 'r5', valor: 'R5' },
    { chave: 'fuego', valor: 'Fuego' },
    { chave: '19', valor: '19' },
    { chave: '11', valor: '11' },
  ];
  
  export default modelosRenault;
  