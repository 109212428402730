<template>
  <div :class="nomePagina == 'estoque' ? 'containerr' : 'container80'">
    <div :class="isLargeScreen? 'segura' : 'seguraMobile'">
      <div v-if="nomePagina == 'estoque'" class="segura-filtros">
        <Filtros
          @carrosFiltrados="setCarrosFiltrados"
          :marcasDisponiveis="marcasDisponiveis"
          :modelosDisponiveis="modelosDisponiveis"
          :carros="cars"
          :isMobile="!isLargeScreen"
          :nomePagina="nomePagina"
          :drawer="drawer"
          @setDrawer="setDrawerFunction"
          :search="search"
        ></Filtros>
      </div>

      <div v-if="!isLargeScreen" class="texto-estoque">
        <div class="texto-e-filtro">
          <h2 v-if="nomePagina == 'estoque'">Confira nosso estoque</h2>
          <h2 v-if="nomePagina == 'home'">Mais Recentes</h2>
          <h2 v-if="nomePagina == 'destaques'">Nossos Destaques</h2>
          <h2 v-if="nomePagina == 'mais-procurados'">Mais Procurados</h2>
          <div v-if="nomePagina == 'estoque' && !isLargeScreen">
            <v-btn aria-label="Filtrar" class="ml-5" color="error" @click="changeDrawerFunction">
              <v-icon left>mdi mdi-filter-plus-outline</v-icon>
              Filtrar
            </v-btn>
          </div>
        </div>
      </div>

      <div :class="nomePagina == 'estoque' && isLargeScreen ? 'coluna-listaCarros-estoque' : 'coluna-listaCarros'">
        <div v-if="isLargeScreen" :class="isMobile ? 'ml-10 texto-estoque' : 'mb-10 texto-estoque'">
          <h2 v-if="nomePagina == 'estoque'">Confira nosso estoque</h2>
          <h2 v-if="nomePagina == 'home'">Mais Recentes</h2>
          <h2 v-if="nomePagina == 'destaques'">Nossos Destaques</h2>
          <h2 v-if="nomePagina == 'mais-procurados'">Mais Procurados</h2>
        </div>
        <div class="cars" ref="scrollContainer">
          <v-card
            v-for="car in carsFiltrados" :key="car.id"
            :class="nomePagina == 'estoque' ? 'car-card' : 'car-cardOther'"
            @click="goToViewPage(car.id, car.Modelo)"
          >
            <v-img
              alt="Imagem do Carro"
              v-if="car.downloadURL"
              lazy-src
              :src="car.downloadURL"
              aspect-ratio="2.5"
              class="car-image"
            ></v-img>
            <div>
              <div class="card-padding">
                <h3 style="font-weight: 900">
                  {{ car.Marca.toUpperCase() }}
                  <span>{{ car.Modelo.toUpperCase() }}</span>
                </h3>
                <p class="text">{{ car.Versao.toUpperCase() }}</p>
                <div class="chips">
                  <v-chip small outlined>{{ car.Cambio.toUpperCase() }}</v-chip>
                  <v-chip small outlined>{{ car.Combustivel.toUpperCase() }}</v-chip>
                  <v-chip small outlined>{{ car.Carroceria.toUpperCase() }}</v-chip>
                </div>
              </div>
              <div class="card-padding2 flex-container mt-5">
                <h1 class="preco">{{ formatarBRL(car.Preco) }}</h1>
                <div class="d-flex mt-1">
                  <span class="descricao text mt-2">{{ car.Ano + ' / ' + car.AnoModelo }}</span>
                  <p class="ml-auto descricao text mt-2">{{ validarQuilometragem(car.Quilometragem) }}</p>
                </div>
                <div v-if="!isMobile" class="center-button mt-5">
                  <v-btn color="error" :class="car.PrecoFake ? 'btn-card mb-1' : 'btn-card'">Mais Detalhes</v-btn>
                </div>
              </div>
            </div>
          </v-card>


        </div>
        <div class="center-button-container">
          <span>Carros encontrados: {{ carrosEncontrados }}</span>
          <v-btn :loading="btnIsLoading" class="ma-auto" color="error" @click="loadMoreCars">Mostrar Mais</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Conteudo from "@/mixins/Conteudo";
import Filtros from "../components/Filtros.vue";
export default {
  mixins: [Conteudo],
  components: { Filtros },
};
</script>

<style scoped>
.center-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; 
}

.texto-estoque {
  top: 0;
  border-radius: 20px;
  padding: 10px;
  margin: auto;
  border: none !important;
  text-align: start;
  z-index: 100;
  margin-left: 30px !important;
  margin-right: 55px !important;
}

.texto-estoque h2 {
  color: #3d3d3d !important;
}
.cars {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100% !important;
  min-width: 100%;
  overflow-y: scroll;
  height: auto;
  max-height: 800px;
}
.btn-filtro {
  padding: 0px 20px 20px 20px;
  background-color: white;
  text-align: end;
  border: none !important;
  box-shadow: none !important;
}

.v-input--checkbox.v-input--dense {
  margin-top: 10px !important;
}
.v-expansion-panels {
  margin-top: 10px;
}
.segura {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}
.seguraMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}

.segura-filtros {
  width: 400px !important;
  display: flex;
  margin-top: 95px;
}

.coluna-listaCarros-estoque {
  height: 900px;
  margin-left: 50px;
  width: 100%;
}

.coluna-listaCarros {
  width: 100%;
}
.chips {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0px 0px;
  margin-top: 10px;
}

.v-chip {
  font-size: 11px !important;
  color: rgb(156, 154, 154) !important;
  font-weight: 600;
}

.risc {
  color: black;
  text-decoration: line-through;
}
.car-card {
  border-radius: 30px !important;
  height: 450px;
  width: 300px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03) !important;
  margin-right: 30px;
  margin-bottom: 30px;
}

.car-cardOther {
  margin: auto;
  border-radius: 30px !important;
  height: 450px;
  width: 300px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03) !important;
  margin-bottom: 30px;
}

.car-image {
  height: 40% !important;
  object-position: center bottom;
}

.card-padding {
  padding: 20px 0px 0px 30px;
}

.card-padding2 {
  height: 145px;
  padding: 0px 30px 0px 30px;
  margin-top: 10px !important;
}

.flex-container {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  flex-direction: column; 
}
.descricao {
  font-size: 13px;
}

.text {
  color: rgb(158, 157, 157);
  font-size: 13px;
  font-weight: 500;
  margin-top: 8px !important;
}

.red-text {
  color: red;
}

.btn-card {
  height: 45px !important;
  width: 100%;
  background-color: red;
  color: red;
  border-radius: 20px;
  text-transform: none;
  box-shadow: none;
}

.center-button {
  width: 100%;
  margin: auto;
  margin-top: 10px;
}

.preco {
  line-height: 1;
  color: #3d3d3d;
  font-size: 25px;
}

.containerr {
  width: 100% !important;
  display: block;
  margin: auto;
  margin-bottom: 80px !important;
}

.container80 {
  width: 80% !important;
  display: block;
  margin: auto;
}

.btn {
  padding: 40px;
}

.mobile {
  margin-top: 0;
}

@media (max-width: 1300px) {
  .texto-e-filtro {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .segura-filtros {
    margin-top: 0;
  }
  .cars {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100% !important;
    min-width: 100% !important;
    overflow-y: scroll;
    overflow-x: hidden;
    height: auto;
    max-height: 800px;
    margin-top: 10px !important;
  }
  .v-card {
    box-shadow: none !important;
    border: none !important;
  }
  .texto-estoque {
    padding: 20px;
    width: 100%;
    margin: auto;
    border: none;
    position: unset;
    z-index: 0;
    text-align: center !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    box-shadow: none !important;
  }

  .texto-estoque h2 {
    font-size: 18px;
  }

  .segura {
    display: block;
    margin: auto;
  }


  .containerr {
    width: 100% !important;
  }

  .container80 {
    margin: auto;
    padding: 0px;
    width: 100% !important;
  }

  .botao {
    width: 100%;
  }

  .search-button {
    width: 100% !important;
    background-color: white;
  }

  .cards {
    margin: auto;
    width: 100%;
    padding: 5px;
  }

  .car-image {
    height: 40% !important;
    object-position: center bottom;
  }

  .car-card {
    height: 450px;
    width: 350px !important;
  }

  .car-cardOther {
    height: 450px;
    width: 300px !important;
  }

  .padding-card {
    padding: 10px;
  }

  .btn-card {
    width: 100%;
  }
}

@media (max-width: 700px) {

  
  .containerr {
    height: 520px;
  }

  .coluna-listaCarros {
    margin-top: 10px;
  }
  .cars {
    margin: auto;
    padding: 10px;
    height: 550px;
    display: block !important;
    margin-top: 50px;
    overflow-y: scroll !important;
  }

  .car-card {
    display: flex;
    border-radius: 20px !important;
    height: 130px;
    width: 100% !important;
    min-width: 350px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03) !important;
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .car-cardOther {
    display: flex;
    border-radius: 20px !important;
    height: 130px;
    width: 100% !important;
    min-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03) !important;
    margin-right: 0px;
    margin-bottom: 30px;
  }

  
  .car-image {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0;
    width: 150px;
    max-width: 150px;
    height: 130px !important;
    object-position: center;
  }
  .card-padding h3 {
    font-size: 14px !important;
  }
  .preco {
    font-size: 17px;
  }

  .risc {
    font-size: 10px;
  }


  .v-chip {
    font-size: 6px !important;
  }

  .chips {
    margin: 0;
    margin-top: 5px;
  }

  .text {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-size: 11px;
  }

  .card-padding2 {
    height: 50px;
    padding: 20px !important;
    padding-right: 30px !important;
  }

  .card-padding {
    padding: 10px 20px !important;
    padding-bottom: 0 !important;
  }

  .descricao {
    font-size: 10px;
  }

  .seguraMobile {
    display: block;
    margin: auto;
    height: 550px;
    margin-bottom: 50px
  }

  .center-button-container {
  margin-left: 20px; /* Ajuste conforme necessário */
}
}
</style>
