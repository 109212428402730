const coresCarro = [
    { chave: "Preto", valor: "Preto" },
    { chave: "Branco", valor: "Branco" },
    { chave: "Prata", valor: "Prata" },
    { chave: "Cinza", valor: "Cinza" },
    { chave: "Vermelho", valor: "Vermelho" },
    { chave: "Azul", valor: "Azul" },
    { chave: "Verde", valor: "Verde" },
    { chave: "Amarelo", valor: "Amarelo" },
    { chave: "Laranja", valor: "Laranja" },
    { chave: "Roxo", valor: "Roxo" },
    { chave: "Marrom", valor: "Marrom" },
    { chave: "Bege", valor: "Bege" },
    { chave: "Dourado", valor: "Dourado" },
    { chave: "Outra", valor: "Outra" },
    { chave: "Azul Marinho", valor: "Azul Marinho" },
    { chave: "Verde Oliva", valor: "Verde Oliva" },
    { chave: "Bordeaux", valor: "Bordeaux" },
    { chave: "Rosa", valor: "Rosa" },
    { chave: "Lilás", valor: "Lilás" },
    { chave: "Turquesa", valor: "Turquesa" },
    { chave: "Bege Metálico", valor: "Bege Metálico" },
    { chave: "Prata Galáctico", valor: "Prata Galáctico" },
    { chave: "Azul Celeste", valor: "Azul Celeste" },
    { chave: "Cobre", valor: "Cobre" },
    { chave: "Cinza Espacial", valor: "Cinza Espacial" },
  ];
  
  export default coresCarro;
  