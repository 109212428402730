const modelosHonda = [
  { chave: "", valor: "" },
  { chave: "civic", valor: "Civic" },
  { chave: "fit", valor: "Fit" },
  { chave: "hr-v", valor: "HR-V" },
  { chave: "cr-v", valor: "CR-V" },
  { chave: "city", valor: "City" },
  { chave: "accord", valor: "Accord" },
  { chave: "wr-v", valor: "WR-V" },
  { chave: "pilot", valor: "Pilot" },
  { chave: "ridgeline", valor: "Ridgeline" },
  { chave: "civic-type-r", valor: "Civic Type R" },
  { chave: "br-v", valor: "BR-V" },
  { chave: "odyssey", valor: "Odyssey" },
  { chave: "brio", valor: "Brio" },
  { chave: "legend", valor: "Legend" },
  { chave: "cr-z", valor: "CR-Z" },
  { chave: "s2000", valor: "S2000" },
  { chave: "element", valor: "Element" },
  { chave: "insight", valor: "Insight" },
  { chave: "crx", valor: "CRX" },
  { chave: "prelude", valor: "Prelude" },
  { chave: "passport", valor: "Passport" },
  { chave: "fcx-clarity", valor: "FCX Clarity" },
  { chave: "integra", valor: "Integra" },
  { chave: "vamos", valor: "Vamos" },
  { chave: "life", valor: "Life" },
  { chave: "acty", valor: "Acty" },
  { chave: "zest", valor: "Zest" },
  { chave: "capa", valor: "Capa" },
  { chave: "that-s", valor: "That S" },
  { chave: "grace", valor: "Grace" },
  { chave: "n-box", valor: "N-BOX" },
  { chave: "vezel", valor: "Vezel" },
  { chave: "shuttle", valor: "Shuttle" },
  { chave: "jade", valor: "Jade" },
  { chave: "element", valor: "Element" },
  { chave: "city", valor: "City" },
  { chave: "z360", valor: "Z360" },
  { chave: "acty", valor: "Acty" },
  { chave: "capa", valor: "Capa" },
  { chave: "that-s", valor: "That S" },
  { chave: "freed", valor: "Freed" },
  { chave: "mobilio", valor: "Mobilio" },
  { chave: "crosstour", valor: "Crosstour" },
  { chave: "airwave", valor: "Airwave" },
];

export default modelosHonda;
