import { auth, db } from "@/firebase.js";
import router from "@/router";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { mapActions } from "vuex";
import { getAuth } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import tiposTransmissao from "../listas/transmissao";
import tiposDirecao from "../listas/direcao";
import marcasDeCarro from "../listas/marcas";
import modelosKia from "../listas/kia";
import modelosFord from "../listas/ford";
import modelosAudi from "../listas/audi";
import modelosBmw from "../listas/bmw";
import modelosChevrolet from "../listas/chevrolet";
import modelosFiat from "../listas/fiat";
import modelosHonda from "../listas/honda";
import modelosHyundai from "../listas/hyundai";
import modelosMercedes from "../listas/mercedes";
import modelosMitsubishi from "../listas/mitsubishi";
import modelosNissan from "../listas/nissan";
import modelosToyota from "../listas/toyota";
import modelosVolkswagen from "../listas/volkswagen";
import modelosRenault from "../listas/renault";
import listaCor from "../listas/cor";
import listaCarroceria from "../listas/carroceria";
import listaCombustivel from "../listas/combustivel";
import listaCidade from "../listas/cidades";
import listaDirecao from "../listas/direcao";
import { toBase64 } from "../base64";
import { v4 as uuidv4 } from "uuid";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  data: () => ({
    rules: {
      required: (value) => !!value || "Campo obrigatório",
    },
    newCar: {},
    uploadingNewPhotos: false,
    loading: false,
    newCar: {
      Nome: "",
      Descricao: "",
      Marca: null,
      Modelo: null,
      Cor: "",
      Ano: null,
      Carroceria: "",
      Versao: "",
      FinalPlaca: "",
      Quilometragem: "",
      Combustivel: null,
      Cidade: "",
      Fotos: [],
      Preco: "",
      PrecoFake: "",
      Placa: "",
      UnicoDono: false,
      Recentes: false,
      AceitaTroca: false,
      MaisProcurado: false,
      Destaque: false,
      Manual: false,
      ChaveReserva: false,
      Opcionais: [],
    },
    anosDisponiveis: [],
    tiposCambio: tiposTransmissao.map((marca) => marca.valor),
    direcao: tiposDirecao.map((marca) => marca.valor),
    marcas: marcasDeCarro.map((marca) => marca.valor),
    listaCores: listaCor.map((marca) => marca.valor),
    listaCarrocerias: listaCarroceria.map((marca) => marca.valor),
    listaCombustiveis: listaCombustivel.map((marca) => marca.valor),
    listaCidades: listaCidade.map((marca) => marca.valor),
    listaDirecoes: listaDirecao.map((marca) => marca.valor),
    modelos: {},
  }),
  computed: {
    isEditing() {
      return !!this.$route.params.id;
    },
  },

  methods: {
    async updateCar() {
      if (!this.$refs.form.validate()) {
        window.scrollTo({
          top: 500,
          behavior: "smooth", // Isso faz com que o scroll seja suave
        });
        return alert("Preencha os campos!");
      }
      this.loading = true;
      const carId = this.$route.params.id;

      const carDoc = doc(db, "cars", carId);
      await updateDoc(carDoc, {
        Nome: this.newCar.Nome,
        Marca: this.newCar.Marca,
        Modelo: this.newCar.Modelo,
        Cor: this.newCar.Cor,
        Versao: this.newCar.Versao,
        Ano: this.newCar.Ano,
        Carroceria: this.newCar.Carroceria,
        FinalPlaca: this.newCar.FinalPlaca,
        Quilometragem: this.newCar.Quilometragem,
        Combustivel: this.newCar.Combustivel,
        Cidade: this.newCar.Cidade,
        AnoModelo: this.newCar.AnoModelo,
        Preco: this.newCar.Preco,
        Direcao: this.newCar.Direcao,
        Descricao: this.newCar?.Descricao,
        Opcionais: this.newCar.Opcionais,
        Cambio: this.newCar.Cambio,
        MaisProcurado: this.newCar.MaisProcurado,
        Destaque: this.newCar.Destaque,
        PrecoFake: this.newCar.PrecoFake,
        Recentes: this.newCar.Recentes,
        UnicoDono: this.newCar.UnicoDono,
        AceitaTroca: this.newCar.AceitaTroca,
        Placa: this.newCar.Placa,
        Manual: this.newCar.Manual,
        ChaveReserva: this.newCar.ChaveReserva,
      });

      if (this.uploadingNewPhotos && this.newCar.Fotos.length > 0) {
        const storage = getStorage();
        const storageRef = ref(storage, `fotos/${carId}`);

        await Promise.all(
          this.newCar.Fotos.map(async (foto) => {
            await uploadBytes(storageRef, foto);
          })
        );

        const newFotoUrls = await Promise.all(
          this.newCar.Fotos.map(async (_, index) => {
            const newStorageRef = ref(storage, `fotos/${carId}_${index}`);
            await uploadBytes(newStorageRef, this.newCar.Fotos[index]);
            return getDownloadURL(newStorageRef);
          })
        );

        this.newCar.Fotos = newFotoUrls;
        await updateDoc(carDoc, { Fotos: this.newCar.Fotos });
      }

      this.loading = false;
      router.push({ name: "home" });
    },

    updateCurrentHour() {
      const now = new Date();
      return now;
    },

    handlePhotoUpload() {
      this.uploadingNewPhotos = true;
    },

    async addNewCar(e) {
      if (!this.$refs.form.validate()) {
        window.scrollTo({
          top: 500,
          behavior: "smooth", // Isso faz com que o scroll seja suave
        });
        return alert("Preencha os campos!");
      }

      this.loading = true;
      if (this.newCar?.Fotos?.length == 0)
        return alert("É necessário inserir uma imagem do veículo!");
      const fotosUrls = await this.uploadFotos(this.newCar.Fotos);
      this.newCar.Fotos = fotosUrls;
      addDoc(collection(db, "cars"), this.newCar);
      this.newCar = {
        Nome: "",
        Marca: null,
        Modelo: null,
        Cor: "",
        Ano: null,
        Carroceria: "",
        FinalPlaca: "",
        Quilometragem: "",
        Descricao: "",
        Manual: false,
        ChaveReserva: false,
        Combustivel: null,
        Cidade: "",
        Fotos: [],
        Opcionais: [],
        MaisProcurado: this.newCar.MaisProcurado,
        Destaque: this.newCar.Destaque,
        PrecoFake: this.newCar.PrecoFake,
        Recentes: this.newCar.Recentes,
      };

      this.loading = false;
      router.push({ name: "home" });
    },
    updateModelos() {
      const marcaSelecionada = this.newCar.Marca;
      this.$set(
        this.modelos,
        marcaSelecionada,
        this.getModelosForMarca(marcaSelecionada)
      );
    },
    async deleteCar() {
      const confirmation = window.confirm(
        "Tem certeza que deseja deletar este carro?"
      );

      if (confirmation) {
        try {
          const carId = this.$route.params.id;
          const carDoc = doc(db, "cars", carId);
          const carSnapshot = await getDoc(carDoc);

          await deleteDoc(carDoc);

          if (carSnapshot.exists()) {
            const imagens = carSnapshot.data().Fotos;
            await Promise.all(
              imagens.map(async (url) => {
                const imageRef = ref(getStorage(), url);
                await deleteObject(imageRef);
              })
            );
          }
          this.$router.push({ name: "list" });
        } catch (error) {
          console.error("Erro ao deletar o carro:", error);
        }
      }
    },
    uploadFotos(e) {
      const storage = getStorage();

      const fotosPromises = this.newCar.Fotos.map(async (foto, index) => {
        const fotoId = uuidv4();
        const storageRef = ref(storage, `fotos/${fotoId}`);
        await uploadBytes(storageRef, foto);
        const fotoUrl = await getDownloadURL(storageRef);
        return fotoUrl;
      });

      return Promise.all(fotosPromises);
    },

    isLogged() {
      auth = getAuth();
      return !!auth.currentUser;
    },

    getModelosForMarca(marca) {
      switch (marca) {
        case "Audi":
          return modelosAudi.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Bmw":
          return modelosBmw.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Chevrolet":
          return modelosChevrolet.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Fiat":
          return modelosFiat.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Ford":
          return modelosFord.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Honda":
          return modelosHonda.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Hyundai":
          return modelosHyundai.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Kia":
          return modelosKia.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Mercedes":
          return modelosMercedes.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Mitsubishi":
          return modelosMitsubishi.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Nissan":
          return modelosNissan.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Toyota":
          return modelosToyota.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Volkswagen":
          return modelosVolkswagen.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Renault":
          return modelosRenault.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        default:
          return [];
      }
    },
  },

  async created() {
    if (this.$route.params.id) {
      const carId = this.$route.params.id;

      const carDoc = doc(db, "cars", carId);
      const carSnapshot = await getDoc(carDoc);

      if (carSnapshot.exists()) {
        this.newCar = { id: carSnapshot.id, ...carSnapshot.data() };
      }
    }

    const anos = [];
    for (let ano = 2024; ano >= 1920; ano--) {
      anos.push(ano);
    }
    this.anosDisponiveis = anos;
  },

  mounted() {
    this.marcas.forEach((marca) => {
      this.$set(this.modelos, marca, []);
    });

    const anos = [];
    for (let ano = 2024; ano >= 1920; ano--) {
      anos.push(ano);
    }
    this.anosDisponiveis = anos;
  },
};
