const marcasDeCarro = [
    { chave: 'toyota', valor: 'Toyota' },
    { chave: 'honda', valor: 'Honda' },
    { chave: 'ford', valor: 'Ford' },
    { chave: 'chevrolet', valor: 'Chevrolet' },
    { chave: 'volkswagen', valor: 'Volkswagen' },
    { chave: 'nissan', valor: 'Nissan' },
    { chave: 'bmw', valor: 'BMW' },
    { chave: 'mercedes', valor: 'Mercedes-Benz' },
    { chave: 'audi', valor: 'Audi' },
    { chave: 'hyundai', valor: 'Hyundai' },
    { chave: 'kia', valor: 'Kia' },
    { chave: 'mitsubishi', valor: 'Mitsubishi' },
    { chave: 'fiat', valor: 'Fiat' },
    { chave: 'renault', valor: 'Renault' },
  ];
  
  export default marcasDeCarro;
  