const tipoDirecao = [
    { chave: "Hidráulica", valor: "Hidráulica" },
      { chave: "Elétrica", valor: "Elétrica" },
      { chave: "Mecânica", valor: "Mecânica" },
      { chave: "Assistida Eletricamente", valor: "Assistida Eletricamente" },
      { chave: "Direção Ativa", valor: "Direção Ativa" },
      { chave: "Direção Adaptativa", valor: "Direção Adaptativa" }
    ];
    
    export default tipoDirecao;
    