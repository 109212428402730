<template>
  <div>
    <footer class="footer">
      <div class="footerbackground"></div>
      <a class="whatsapp" :href="link" target="_blank">
        <v-img lazy-src class="img mr-2 mb-3" src="../assets/whatsapp-rodape.png" alt="Imagem Rodape WhatsApp"></v-img
      ></a>
      <!-- Conteúdo do rodapé -->
    </footer>
  </div>
</template>

<script>
export default {
  computed: {
    link() {
      const numeroTelefone = "5561994442213";
      const mensagem = encodeURIComponent(
        "Olá, entrei em contato através do site. Gostaria de informações."
      );
      return `https://api.whatsapp.com/send?phone=${numeroTelefone}&text=${mensagem}`;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  pointer-events: none !important;
}

.footerbackground {
  pointer-events: none !important;
  width: 90%;
}

.img {
  width: 70px;
  height: 70px;
}

.whatsapp {
  pointer-events: all !important;
}

a {
  font-size: 80px;
}

span {
  color: #4dc95b;
}

@media screen and (max-width: 600px) {
  a {
    font-size: 50px;
  }

  .img {
    width: 70px;
    height: 70px;
  }
}
</style>
