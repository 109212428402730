const tiposCarroceria = [
    { chave: "Sedã", valor: "Sedã" },
    { chave: "Hatch", valor: "Hatch" },
    { chave: "SUV", valor: "SUV" },
    { chave: "Coupé", valor: "Coupé" },
    { chave: "Conversível", valor: "Conversível" },
    { chave: "Picape", valor: "Picape" },
    { chave: "Minivan", valor: "Minivan" },
    { chave: "Van", valor: "Van" },
    { chave: "Wagon", valor: "Perua (Station Wagon)" },
    { chave: "Crossover", valor: "Crossover" },
    { chave: "Roadster", valor: "Roadster" },
    { chave: "Jipe", valor: "Jipe" },
    { chave: "Microcarro", valor: "Microcarro" },
    { chave: "Compacto", valor: "Compacto" },
    { chave: "Triciclo", valor: "Triciclo" },
    { chave: "Caminhão", valor: "Caminhão" },
    { chave: "Ônibus", valor: "Ônibus" },
    { chave: "Buggy", valor: "Buggy" },
    { chave: "Outro", valor: "Outro" },
  ];
  
  export default tiposCarroceria;
  