const modelosMercedes = [
  { chave: "", valor: "" },
  { chave: "a-class", valor: "Classe A" },
  { chave: "b-class", valor: "Classe B" },
  { chave: "c-class", valor: "Classe C" },
  { chave: "e-class", valor: "Classe E" },
  { chave: "s-class", valor: "Classe S" },
  { chave: "cla-class", valor: "CLA" },
  { chave: "cls-class", valor: "CLS" },
  { chave: "sl-class", valor: "SL" },
  { chave: "slc-class", valor: "SLC" },
  { chave: "glc-class", valor: "GLC" },
  { chave: "gle-class", valor: "GLE" },
  { chave: "gla-class", valor: "GLA" },
  { chave: "glb-class", valor: "GLB" },
  { chave: "gl-class", valor: "GL" },
  { chave: "g-class", valor: "Classe G" },
  { chave: "eqa", valor: "EQA" },
  { chave: "eqb", valor: "EQB" },
  { chave: "eqc", valor: "EQC" },
  { chave: "eqe", valor: "EQE" },
  { chave: "eqs", valor: "EQS" },
  { chave: "amg-a-class", valor: "Mercedes-AMG Classe A" },
  { chave: "amg-c-class", valor: "Mercedes-AMG Classe C" },
  { chave: "amg-e-class", valor: "Mercedes-AMG Classe E" },
  { chave: "amg-gt", valor: "Mercedes-AMG GT" },
  { chave: "v-class", valor: "V-Class" },
  { chave: "x-class", valor: "Classe X" },
  { chave: "metris", valor: "Metris" },
  { chave: "maybach-s-class", valor: "Mercedes-Maybach Classe S" },
  { chave: "maybach-gls", valor: "Mercedes-Maybach GLS" },
  { chave: "sprinter", valor: "Sprinter" },
  { chave: "x-klasse", valor: "X-Klasse" },
  { chave: "marco-polo", valor: "Marco Polo" },
  { chave: "vito", valor: "Vito" },
  { chave: "citan", valor: "Citan" },
  { chave: "sprinter-tourer", valor: "Sprinter Tourer" },
  { chave: "sprinter-truck", valor: "Sprinter Truck" },
  { chave: "sprinter-van", valor: "Sprinter Van" },
  { chave: "sprinter-4x4", valor: "Sprinter 4x4" },
  { chave: "sprinter-6x6", valor: "Sprinter 6x6" },
  { chave: "sprinter-8x8", valor: "Sprinter 8x8" },
  { chave: "sprinter-classic", valor: "Sprinter Classic" },
  { chave: "sprinter-city", valor: "Sprinter City" },
  { chave: "sprinter-transfer", valor: "Sprinter Transfer" },
  { chave: "sprinter-school", valor: "Sprinter School" },
];

export default modelosMercedes;
