const tipoTransmissao = [
  {chave: "", valor: ""},
  {chave: "Manual", valor: "Manual"},
  {chave:"Automático", valor: "Automatico"},
  {chave:"SemiAutomatico", valor: "Semi Automático"},
  {chave:"Automático com Modo Manual", valor: "Automático com Modo Manual"},
  {chave:"Semi-Automático", valor: "Semi automatico"}
  ];
  
  export default tipoTransmissao;
  