const modelosHyundai = [
  { chave: "", valor: "" },
  { chave: "hb20", valor: "HB20" },
  { chave: "creta", valor: "Creta" },
  { chave: "ix35", valor: "ix35" },
  { chave: "i30", valor: "i30" },
  { chave: "elantra", valor: "Elantra" },
  { chave: "tucson", valor: "Tucson" },
  { chave: "santa-fe", valor: "Santa Fe" },
  { chave: "kona", valor: "Kona" },
  { chave: "veloster", valor: "Veloster" },
  { chave: "sonata", valor: "Sonata" },
  { chave: "azera", valor: "Azera" },
  { chave: "equus", valor: "Equus" },
  { chave: "genesis", valor: "Genesis" },
  { chave: "ioniq", valor: "Ioniq" },
  { chave: "palisade", valor: "Palisade" },
  { chave: "grand-santa-fe", valor: "Grand Santa Fe" },
  { chave: "grand-i10", valor: "Grand i10" },
  { chave: "i20", valor: "i20" },
  { chave: "i40", valor: "i40" },
  { chave: "verna", valor: "Verna" },
  { chave: "accent", valor: "Accent" },
  { chave: "getz", valor: "Getz" },
  { chave: "atos", valor: "Atos" },
  { chave: "xcent", valor: "Xcent" },
  { chave: "venue", valor: "Venue" },
  { chave: "i10", valor: "i10" },
  { chave: "creta", valor: "Creta" },
  { chave: "grand-i10-nios", valor: "Grand i10 Nios" },
  { chave: "sonata-n-line", valor: "Sonata N Line" },
  { chave: "ioniq-5", valor: "Ioniq 5" },
  { chave: "i20-n", valor: "i20 N" },
  { chave: "bayon", valor: "Bayon" },
  { chave: "nexo", valor: "Nexo" },
  { chave: "starex", valor: "Starex" },
  { chave: "genesis-g80", valor: "Genesis G80" },
  { chave: "genesis-g90", valor: "Genesis G90" },
  { chave: "kona-n", valor: "Kona N" },
  { chave: "tucson-n-line", valor: "Tucson N Line" },
  { chave: "palisade-calligraphy", valor: "Palisade Calligraphy" },
  { chave: "santa-fe-calligraphy", valor: "Santa Fe Calligraphy" },
  { chave: "i30-n", valor: "i30 N" },
  { chave: "sonata-n-line", valor: "Sonata N Line" },
  { chave: "elantra-n", valor: "Elantra N" },
  { chave: "elantra-n-line", valor: "Elantra N Line" },
  { chave: "tucson-n-line", valor: "Tucson N Line" },
  { chave: "tucson-hybrid", valor: "Tucson Hybrid" },
  { chave: "ioniq-6", valor: "Ioniq 6" },
  { chave: "ioniq-7", valor: "Ioniq 7" },
  { chave: "staria", valor: "Staria" },
];

export default modelosHyundai;
