const modelosKia = [
  { chave: "", valor: "" },
  { chave: "picanto", valor: "Picanto" },
  { chave: "rio", valor: "Rio" },
  { chave: "cerato", valor: "Cerato" },
  { chave: "forte", valor: "Forte" },
  { chave: "seltos", valor: "Seltos" },
  { chave: "sportage", valor: "Sportage" },
  { chave: "sorento", valor: "Sorento" },
  { chave: "telluride", valor: "Telluride" },
  { chave: "soul", valor: "Soul" },
  { chave: "stinger", valor: "Stinger" },
  { chave: "cadenza", valor: "Cadenza" },
  { chave: "k900", valor: "K900" },
  { chave: "niro", valor: "Niro" },
  { chave: "ev6", valor: "EV6" },
  { chave: "ev7", valor: "EV7" },
  { chave: "ev8", valor: "EV8" },
  { chave: "ev9", valor: "EV9" },
  { chave: "ev10", valor: "EV10" },
  { chave: "mohave", valor: "Mohave" },
  { chave: "optima", valor: "Optima" },
  { chave: "quoris", valor: "Quoris" },
  { chave: "soul-ev", valor: "Soul EV" },
  { chave: "niro-ev", valor: "Niro EV" },
  { chave: "niro-plug-in-hybrid", valor: "Niro Plug-in Hybrid" },
  { chave: "niro-hybrid", valor: "Niro Hybrid" },
  { chave: "soul-ev", valor: "Soul EV" },
  { chave: "niro-ev", valor: "Niro EV" },
  { chave: "niro-plug-in-hybrid", valor: "Niro Plug-in Hybrid" },
  { chave: "niro-hybrid", valor: "Niro Hybrid" },
  { chave: "soul-ev", valor: "Soul EV" },
  { chave: "niro-ev", valor: "Niro EV" },
  { chave: "niro-plug-in-hybrid", valor: "Niro Plug-in Hybrid" },
  { chave: "niro-hybrid", valor: "Niro Hybrid" },
  { chave: "stonic", valor: "Stonic" },
  { chave: "xcross", valor: "XCross" },
  { chave: "telluride", valor: "Telluride" },
  { chave: "soul", valor: "Soul" },
  { chave: "stinger", valor: "Stinger" },
  { chave: "cadenza", valor: "Cadenza" },
  { chave: "k900", valor: "K900" },
  { chave: "niro", valor: "Niro" },
  { chave: "ev6", valor: "EV6" },
  { chave: "ev7", valor: "EV7" },
  { chave: "ev8", valor: "EV8" },
  { chave: "ev9", valor: "EV9" },
  { chave: "ev10", valor: "EV10" },
  { chave: "mohave", valor: "Mohave" },
  { chave: "optima", valor: "Optima" },
  { chave: "quoris", valor: "Quoris" },
];

export default modelosKia;
