<template>
  <div>
    <v-navigation-drawer
      v-if="isMobile && settedDrawer"
      v-model="settedDrawer"
      app
    >
      <div v-if="nomePagina == 'estoque'" class="coluna-filtros-mobile">
        <div
          v-if="isMobile && nomePagina == 'estoque'"
          class="d-flex justify-center flex-wrap card-filtros-mobile"
        >
          <div class="card-descricao">
            <h3>Filtrar Carros</h3>
            <div class="ml-auto">
              <!-- <v-btn class="mr-2" @click="showMore" color="error">{{
                showMoreFilters ? "-" : "+"
              }}</v-btn> -->
              <v-btn
                aria-label="X"
                @click="settedDrawer = !drawer"
                color="error"
                >X</v-btn
              >
            </div>
          </div>

          <div class="campos-filtro">
            <div class="mt-2 mb-2">
              <p>Marca do carro</p>
              <v-select
                class="filter-field"
                hide-details
                v-model="filtroOrder"
                :items="sortByItems"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Ordenar Por"
              ></v-select>
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                class="filter-field"
                hide-details
                v-model="filtroMarca"
                @change="changedMarca"
                :items="marcasDisponiveis"
                label="Marca"
              ></v-select>
              <v-text-field
                v-if="!filtroMarca"
                class="filter-field"
                hide-details
                v-model="filtroModelo"
                :items="modelosDisponiveis"
                label="Pesquisar por Modelo"
              ></v-text-field>
              <v-autocomplete
                :menu-props="{ bottom: true, offsetY: true }"
                v-if="filtroMarca"
                class="filter-field"
                hide-details
                v-model="filtroModelo"
                :items="modelosDisponiveis"
                label="Modelo"
              ></v-autocomplete>
            </div>
          </div>
          <hr />
          <div class="campos-filtro">
            <h4>Preço</h4>
            <div class="d-flex">
              <v-text-field
                class="filter-field mr-2"
                hide-details
                outlined
                dense
                v-mask="['##.###', '###.###']"
                v-model="filtroMenorPreco"
                label="De"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                class="filter-field ml-2"
                hide-details
                v-model="filtroMaiorPreco"
                label="Até"
              ></v-text-field>
            </div>
          </div>
          <hr />
          <div class="campos-filtro">
            <h4>Ano</h4>
            <div class="d-flex">
              <v-text-field
                class="filter-field mr-2"
                hide-details
                outlined
                dense
                v-mask="'####'"
                v-model="filtroAnoDe"
                label="Ano (De)"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                class="filter-field ml-2"
                hide-details
                v-model="filtroAnoAte"
                label="Ano (Até)"
              ></v-text-field>
            </div>
          </div>
          <hr />
          <div class="campos-filtro">
            <h4>Opcionais</h4>
            <v-checkbox
              v-model="filtroAr"
              dense
              hide-details
              value="Ar Condicionado"
              label="Ar Condicionado"
            ></v-checkbox>
            <v-checkbox
              v-model="filtroVidro"
              dense
              hide-details
              value="Vidro Elétrico"
              label="Vidro Elétrico"
            ></v-checkbox>
            <v-checkbox
              v-model="filtroReboque"
              dense
              hide-details
              value="Engate Reboque"
              label="Engate Reboque"
            ></v-checkbox>
            <v-checkbox
              v-model="filtroMultimidia"
              dense
              hide-details
              value="Multimídia"
              label="Multimídia"
            ></v-checkbox>
            <v-checkbox
              v-model="filtroUnicoDono"
              dense
              hide-details
              value="true"
              label="Unico Dono"
            ></v-checkbox>
            <v-checkbox
              v-model="filtroAceitaTroca"
              dense
              hide-details
              value="true"
              label="Aceita Troca"
            ></v-checkbox>
          </div>

          <hr />

          <div class="campos-filtro">
          <h4>Combustível</h4>
          
          <v-checkbox
            v-model="filtroGasolina"
            dense
            hide-details
            value="Gasolina"
            label="Gasolina"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroAlcool"
            dense
            hide-details
            value="Álcool (Etanol)"
            label="Álcool (Etanol)"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroFlex"
            dense
            hide-details
            value="Flex"
            label="Flex"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroHibrido"
            dense
            hide-details
            value="Híbrido"
            label="Híbrido"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroDiesel"
            dense
            hide-details
            value="Diesel"
            label="Diesel"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroGasNatural"
            dense
            hide-details
            value="Gás Natural"
            label="Gás Natural"
          ></v-checkbox>
          </div>

          <hr />
          <div class="campos-filtro">
            <h4>Categorias</h4>
            <v-checkbox
              v-model="filtroCarroceriaHatch"
              value="Hatch"
              label="Hatch"
              dense
              hide-details
            ></v-checkbox>
            <v-checkbox
              dense
              v-model="filtroCarroceriaSeda"
              value="Sedã"
              label="Sedã"
              hide-details
            ></v-checkbox>
            <v-checkbox
              dense
              v-model="filtroCarroceriaSUV"
              value="SUV"
              label="SUV"
              hide-details
            ></v-checkbox>
            <v-checkbox
              dense
              hide-details
              v-model="filtroCarroceriaPicape"
              value="Picape"
              label="Picape"
            ></v-checkbox>
            <!-- <v-select
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                class="filter-field"
                hide-details
                dense
                v-model="filtroCarroceria"
                :items="carroceriasDisponiveis"
                label="Categorias"
              ></v-select> -->
          </div>

          <hr />
          <div class="campos-filtro">
            <h4>Direção</h4>

            <v-checkbox
              dense
              hide-details
              v-model="filtroDirecaoMecanica"
              value="Mecanica"
              label="Mecânica"
            ></v-checkbox>
            <v-checkbox
              dense
              hide-details
              v-model="filtroDirecaoHidraulica"
              value="Hidraulica"
              label="Hidráulica"
            ></v-checkbox>
            <v-checkbox
              dense
              hide-details
              v-model="filtroDirecaoEletrica"
              value="Eletrica"
              label="Elétrica"
            ></v-checkbox>
            <!-- <v-select
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              class="filter-field"
              hide-details
              dense
              v-model="filtroCambio"
              :items="listaDirecoes"
              label="Direção"
            ></v-select> -->
          </div>
        </div>

        <!-- <div
          v-if="isMobile && nomePagina == 'estoque'"
          class="d-flex justify-center btn"
        >
          <v-btn @click="toggleMobileFilters" color="error">
            {{ !mostrarFiltrosMobile ? "Filtrar Veículos" : "Fechar" }}
          </v-btn>
        </div> -->
      </div>

      <div class="fixed">
        <v-btn
          aria-label="Limpar"
          color="white"
          class="btn-limpar-mobile"
          @click="limparFiltros()"
          >Limpar</v-btn
        >
        <v-btn
          aria-label="Filtrar"
          class="btn-filtrar-mobile"
          color="error"
          @click="settedDrawer = !drawer"
          >Filtrar</v-btn
        >
      </div>
    </v-navigation-drawer>

    <div v-if="nomePagina == 'estoque'" class="coluna-filtros">
      <div
        v-if="!isMobile && nomePagina == 'estoque'"
        class="d-flex justify-center flex-wrap card-filtros"
      >
        <div class="card-descricao">
          <h3>Filtrar Carros</h3>
          <div class="ml-auto">
            <!-- <v-btn class="mr-2" @click="showMore" color="error">{{
                showMoreFilters ? "-" : "+"
              }}</v-btn> -->
            <v-btn  aria-label="X" @click="limparFiltros" color="error">X</v-btn>
          </div>
        </div>

        <div class="campos-filtro">
          <div class="mt-2 mb-2">
            <p>Marca do carro</p>
            <v-select
              class="filter-field"
              :menu-props="{ bottom: true, offsetY: true }"
              hide-details
              v-model="filtroOrder"
              :items="sortByItems"
              label="Ordenar Por"
            ></v-select>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              class="filter-field"
              hide-details
              v-model="filtroMarca"
              @change="changedMarca"
              :items="marcasDisponiveis"
              label="Marca"
            ></v-select>
            <v-text-field
              v-if="!filtroMarca"
              class="filter-field"
              hide-details
              v-model="filtroModelo"
              :items="modelosDisponiveis"
              label="Pesquisar por Modelo"
            ></v-text-field>
            <v-autocomplete
              :menu-props="{ bottom: true, offsetY: true }"
              v-if="filtroMarca"
              class="filter-field"
              hide-details
              v-model="filtroModelo"
              :items="modelosDisponiveis"
              label="Modelo"
            ></v-autocomplete>
          </div>
        </div>
        <hr />
        <div class="campos-filtro">
          <h4>Preço</h4>
          <div class="d-flex">
            <v-text-field
              class="filter-field mr-2"
              hide-details
              outlined
              dense
              v-mask="['##.###', '###.###']"
              v-model="filtroMenorPreco"
              label="De"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              class="filter-field ml-2"
              hide-details
              v-model="filtroMaiorPreco"
              label="Até"
            ></v-text-field>
          </div>
        </div>
        <hr />
        <div class="campos-filtro">
          <h4>Ano</h4>
          <div class="d-flex">
            <v-text-field
              class="filter-field mr-2"
              hide-details
              outlined
              dense
              v-mask="'####'"
              v-model="filtroAnoDe"
              label="Ano (De)"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              class="filter-field ml-2"
              hide-details
              v-model="filtroAnoAte"
              label="Ano (Até)"
            ></v-text-field>
          </div>
        </div>
        <hr />
        <div class="campos-filtro">
          <h4>Opcionais</h4>
          <v-checkbox
            v-model="filtroAr"
            dense
            hide-details
            value="Ar Condicionado"
            label="Ar Condicionado"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroVidro"
            dense
            hide-details
            value="Vidro Elétrico"
            label="Vidro Elétrico"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroReboque"
            dense
            hide-details
            value="Engate Reboque"
            label="Engate Reboque"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroMultimidia"
            dense
            hide-details
            value="Multimídia"
            label="Multimídia"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroUnicoDono"
            dense
            hide-details
            value="true"
            label="Unico Dono"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroAceitaTroca"
            dense
            hide-details
            value="true"
            label="Aceita Troca"
          ></v-checkbox>
        </div>

        <hr />


        <!-- aqui -->
        <div class="campos-filtro">
          <h4>Combustível</h4>
          
          <v-checkbox
            v-model="filtroGasolina"
            dense
            hide-details
            value="Gasolina"
            label="Gasolina"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroAlcool"
            dense
            hide-details
            value="Álcool (Etanol)"
            label="Álcool (Etanol)"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroFlex"
            dense
            hide-details
            value="Flex"
            label="Flex"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroHibrido"
            dense
            hide-details
            value="Híbrido"
            label="Híbrido"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroDiesel"
            dense
            hide-details
            value="Diesel"
            label="Diesel"
          ></v-checkbox>
          <v-checkbox
            v-model="filtroGasNatural"
            dense
            hide-details
            value="Gás Natural"
            label="Gás Natural"
          ></v-checkbox>
        </div>

        <hr />
        <div class="campos-filtro">
          <h4>Categorias</h4>
          <v-checkbox
            v-model="filtroCarroceriaHatch"
            value="Hatch"
            label="Hatch"
            dense
            hide-details
          ></v-checkbox>
          <v-checkbox
            dense
            v-model="filtroCarroceriaSeda"
            value="Sedã"
            label="Sedã"
            hide-details
          ></v-checkbox>
          <v-checkbox
            dense
            v-model="filtroCarroceriaSUV"
            value="SUV"
            label="SUV"
            hide-details
          ></v-checkbox>
          <v-checkbox
            dense
            hide-details
            v-model="filtroCarroceriaPicape"
            value="Picape"
            label="Picape"
          ></v-checkbox>
          <!-- <v-select
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                class="filter-field"
                hide-details
                dense
                v-model="filtroCarroceria"
                :items="carroceriasDisponiveis"
                label="Categorias"
              ></v-select> -->
        </div>

        <hr />
        <div class="campos-filtro">
          <h4>Direção</h4>

          <v-checkbox
            dense
            hide-details
            v-model="filtroDirecaoMecanica"
            value="Mecanica"
            label="Mecânica"
          ></v-checkbox>
          <v-checkbox
            dense
            hide-details
            v-model="filtroDirecaoHidraulica"
            value="Hidraulica"
            label="Hidráulica"
          ></v-checkbox>
          <v-checkbox
            dense
            hide-details
            v-model="filtroDirecaoEletrica"
            value="Eletrica"
            label="Elétrica"
          ></v-checkbox>
          <!-- <v-select
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              class="filter-field"
              hide-details
              dense
              v-model="filtroCambio"
              :items="listaDirecoes"
              label="Direção"
            ></v-select> -->
        </div>
      </div>

      <!-- <div
          v-if="isMobile && nomePagina == 'estoque'"
          class="d-flex justify-center btn"
        >
          <v-btn @click="toggleMobileFilters" color="error">
            {{ !mostrarFiltrosMobile ? "Filtrar Veículos" : "Fechar" }}
          </v-btn>
        </div> -->
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import modelosKia from "../listas/kia";
import modelosFord from "../listas/ford";
import modelosAudi from "../listas/audi";
import modelosBmw from "../listas/bmw";
import modelosChevrolet from "../listas/chevrolet";
import modelosFiat from "../listas/fiat";
import modelosHonda from "../listas/honda";
import modelosHyundai from "../listas/hyundai";
import modelosMercedes from "../listas/mercedes";
import modelosMitsubishi from "../listas/mitsubishi";
import modelosNissan from "../listas/nissan";
import modelosToyota from "../listas/toyota";
import modelosVolkswagen from "../listas/volkswagen";
import modelosRenault from "../listas/renault";
import sortByItems from "@/listas/sortByItems";
export default {
  directives: { mask },
  props: [
    "nomePagina",
    "isMobile",
    "marcasDisponiveis",
    "carros",
    "drawer",
    "search",
  ],
  data: () => ({
    sortByItems: sortByItems.map((sort) => sort.valor),
    filtroGasolina : null,
    filtroAlcool : null,
    filtroFlex : null,
    filtroHibrido : null,
    filtroGasNatural : null,
    filtroDiesel : null,
    filtroOrder: null,
    filtroAnoDe: null,
    filtroAnoAte: null,
    filtroCambio: null,
    filtroAceitaTroca: false,
    filtroUnicoDono: null,
    filtroMultimidia: null,
    filtroReboque: null,
    filtroVidro: null,
    filtroAr: null,
    filtroMarca: null,
    filtroModelo: null,
    filtroCarroceria: null,
    filtroCarroceriaHatch: null,
    filtroCarroceriaPicape: null,
    filtroCarroceriaSUV: null,
    filtroCarroceriaSeda: null,
    filtroDirecaoEletrica: null,
    filtroDirecaoMecanica: null,
    filtroDirecaoHidraulica: null,
    filtroMenorPreco: null,
    filtroMaiorPreco: null,
    carrosFiltrados: {},
    modelos: {},
  }),
  mounted() {
    this.carregarModelos();
  },
  methods: {
    limparFiltros() {
      this.filtroGasolina = null;
      this.filtroAlcool = null;
      this.filtroFlex = null;
      this.filtroHibrido = null;
      this.filtroGasNatural = null;
      this.filtroDiesel = null;
      this.filtroOrder = null;
      this.filtroMarca = null;
      this.filtroModelo = null;
      this.filtroCarroceria = null;
      this.filtroCarroceriaHatch = null;
      this.filtroCarroceriaSeda = null;
      this.filtroCarroceriaSUV = null;
      this.filtroCarroceriaPicape = null;
      this.filtroDirecaoEletrica = null;
      this.filtroDirecaoMecanica = null;
      this.filtroDirecaoHidraulica = null;
      this.filtroAnoDe = null;
      this.filtroAnoAte = null;
      this.filtroMenorPreco = null;
      this.filtroMaiorPreco = null;
      this.filtroAceitaTroca = false;
      this.filtroUnicoDono = null;
      this.filtroMultimidia = null;
      this.filtroReboque = null;
      this.filtroVidro = null;
      this.filtroAr = null;

      this.filtrarCarros();
    },
    getModelosForMarca(marca) {
      switch (marca) {
        case "Audi":
          return modelosAudi.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Bmw":
          return modelosBmw.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Chevrolet":
          return modelosChevrolet.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Fiat":
          return modelosFiat.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Ford":
          return modelosFord.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Honda":
          return modelosHonda.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Hyundai":
          return modelosHyundai.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Kia":
          return modelosKia.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Mercedes":
          return modelosMercedes.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Mitsubishi":
          return modelosMitsubishi.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Nissan":
          return modelosNissan.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Toyota":
          return modelosToyota.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Volkswagen":
          return modelosVolkswagen.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        case "Renault":
          return modelosRenault.map((modelo) => ({
            value: modelo.chave,
            text: modelo.valor,
          }));
        default:
          return [];
      }
    },

    async carregarModelos() {
      if (this.filtroMarca) {
        try {
          const modelos = this.getModelosForMarca(this.filtroMarca);
          if (modelos.length > 0) {
            this.filtroModelo = modelos[0].value;
          }

          this.$set(this.modelos, this.filtroMarca, modelos);
        } catch (error) {
          console.error(
            `Erro ao carregar modelos para ${this.filtroMarca}:`,
            error
          );
        }
      } else {
        this.$set(this.modelos, this.filtroMarca, []);
      }
      if (this.search) {
        this.filtroModelo = this.search;
      }
      this.filtrarCarros();
    },

    filtrarCarros() {
      this.carsFiltrados = this.cars.filter((car) => {
        const filtroMarca = !this.filtroMarca || car.Marca === this.filtroMarca;

        const filtroCarroceria =
          (!this.filtroCarroceriaHatch &&
            !this.filtroCarroceriaSeda &&
            !this.filtroCarroceriaPicape &&
            !this.filtroCarroceriaSUV) ||
          (this.filtroCarroceriaHatch && car.Carroceria === "Hatch") ||
          (this.filtroCarroceriaSeda && car.Carroceria === "Sedã") ||
          (this.filtroCarroceriaSUV && car.Carroceria === "SUV") ||
          (this.filtroCarroceriaPicape && car.Carroceria === "Picape");

        const filtroDirecao =
          (!this.filtroDirecaoEletrica &&
            !this.filtroDirecaoMecanica &&
            !this.filtroDirecaoHidraulica) ||
          (this.filtroDirecaoEletrica && car.Direcao === "Elétrica") ||
          (this.filtroDirecaoMecanica && car.Direcao === "Mecânica") ||
          (this.filtroDirecaoHidraulica && car.Direcao === "Hidráulica");

          const filtroCombustivel = 
      (!this.filtroGasolina &&
       !this.filtroAlcool &&
       !this.filtroFlex &&
       !this.filtroHibrido &&
       !this.filtroDiesel &&
       !this.filtroGasNatural) ||
      (this.filtroGasolina && car.Combustivel === 'Gasolina') ||
      (this.filtroAlcool && car.Combustivel === 'Álcool (Etanol)') ||
      (this.filtroFlex && car.Combustivel === 'Flex') ||
      (this.filtroHibrido && car.Combustivel === 'Híbrido') ||
      (this.filtroDiesel && car.Combustivel === 'Diesel') ||
      (this.filtroGasNatural && car.Combustivel === 'Gás Natural');

          
        const filtroModelo =
          !this.filtroModelo || car.Modelo === this.filtroModelo.toLowerCase();

        const filtroAnoDe = !this.filtroAnoDe || car.Ano >= this.filtroAnoDe;

        const filtroAnoAte = !this.filtroAnoAte || car.Ano <= this.filtroAnoAte;

        const filtroPrecoDe =
          !this.filtroMenorPreco ||
          car.Preco >= parseFloat(this.filtroMenorPreco.replace(/\./g, ""));

        const filtroPrecoAte =
          !this.filtroMaiorPreco ||
          car.Preco <= parseFloat(this.filtroMaiorPreco);

        const filtroAceitaTroca =
          !this.filtroAceitaTroca || car.AceitaTroca == this.filtroAceitaTroca;

        const filtroUnicoDono =
          !this.filtroUnicoDono || car.UnicoDono == this.filtroUnicoDono;

        const filtroMultimidia =
          !this.filtroMultimidia ||
          car.Opcionais.find((x) => x === "Multimídia") != null;

        const filtroReboque =
          !this.filtroReboque ||
          car.Opcionais.find((x) => x == "Engate Reboque");

        const filtroVidro =
          !this.filtroVidro ||
          car.Opcionais.find((x) => x == "Vidro Elétrico") != null;

        const filtroAr =
          !this.filtroAr || car.Opcionais.find((x) => x == "Ar Condicionado");

        return (
          filtroCombustivel &&
          filtroMarca &&
          filtroCarroceria &&
          filtroDirecao &&
          filtroModelo &&
          filtroAnoDe &&
          filtroAnoAte &&
          filtroPrecoDe &&
          filtroPrecoAte &&
          filtroAceitaTroca &&
          filtroUnicoDono &&
          filtroMultimidia &&
          filtroReboque &&
          filtroVidro &&
          filtroAr
        );
      });

      switch (this.filtroOrder) {
        case "Menor Preço":
          this.carsFiltrados.sort((a, b) => a.Preco - b.Preco);
          break;
        case "Maior Preço":
          this.carsFiltrados.sort((a, b) => b.Preco - a.Preco);
          break;
        case "Ano Mais Novo":
          this.carsFiltrados.sort((a, b) => b.Ano - a.Ano);
          break;
        case "Menor KM":
          this.carsFiltrados.sort((a, b) => a.Quilometragem - b.Quilometragem);
          break;
        default:
      }
      this.carrosFiltrados = this.carsFiltrados;
    },

    async changedMarca() {
      this.filtroModelo = null;
      this.search = null;
      await this.carregarModelos();
    },
  },

  watch: {
    filtroDiesel: "filtrarCarros",
    filtroGasNatural: "filtrarCarros",
    filtroHibrido: "filtrarCarros",
    filtroFlex: "filtrarCarros",
    filtroAlcool: "filtrarCarros",
    filtroGasolina: "filtrarCarros",
    filtroOrder: "filtrarCarros",
    filtroAceitaTroca: "filtrarCarros",
    filtroUnicoDono: "filtrarCarros",
    filtroMultimidia: "filtrarCarros",
    filtroReboque: "filtrarCarros",
    filtroVidro: "filtrarCarros",
    filtroAr: "filtrarCarros",
    filtroMarca: "filtrarCarros",
    filtroCarroceriaHatch: "filtrarCarros",
    filtroCarroceriaSeda: "filtrarCarros",
    filtroCarroceriaSUV: "filtrarCarros",
    filtroCarroceriaPicape: "filtrarCarros",
    filtroDirecaoHidraulica: "filtrarCarros",
    filtroDirecaoMecanica: "filtrarCarros",
    filtroDirecaoEletrica: "filtrarCarros",
    filtroModelo: "filtrarCarros",
    filtroCambio: "filtrarCarros",
    filtroAnoDe: "filtrarCarros",
    filtroAnoAte: "filtrarCarros",
    filtroMenorPreco: "filtrarCarros",
    filtroMaiorPreco: "filtrarCarros",

    carrosFiltrados() {
      this.$emit("carrosFiltrados", this.carrosFiltrados);
    },
  },

  computed: {
    modelosDisponiveis: {
      get() {
        return this.modelos[this.filtroMarca] || [];
      },
      set(value) {},
    },

    settedDrawer: {
      get() {
        return this.drawer;
      },
      set(val) {
        this.$emit("setDrawer", val);
      },
    },

    cars: {
      get() {
        return this.carros;
      },
      set() {
        this.$emit("carros", this.cars);
      },
    },
  },
};
</script>

<style scoped>
hr {
  border: 0.1px solid rgba(152, 152, 152, 0.3);
  width: 100%;
  margin-top: 5px;
}
.v-navigation-drawer {
  width: 100% !important;
  background-color: #fdfdfd !important;
}
.card-descricao {
  width: 100%;
  display: flex;
  padding: 30px 0px 0px 10px;
}
.mobile {
  margin-top: 0;
}
.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.campos-filtro {
  width: 100%;
  padding: 10px 30px 20px 30px;
}
.filter-field {
  background-color: #ffffff !important; /* Cor de fundo para os campos */
  box-shadow: none;
  border: 10px green !important;

  margin-top: 10px !important;
}
.coluna-filtros {
  width: 350px;
}
.coluna-filtros-mobile {
  width: 100% !important;
}
.filters p {
  font-size: 14px;
}
.campos-filtro {
  width: 100%;
  padding: 10px;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(224, 224, 224); /* Cor da parte do meio */
  border-radius: 3px;
}
::-webkit-scrollbar-track {
  background-color: transparent; /* Cor do restante do scrollbar */
  border-radius: 5px;
}
.card-filtros {
  margin: auto;
  width: 90%;
  overflow-y: scroll;
  position: initial;
  background-color: white; /* Cor de fundo desejada */
  border-radius: 20px;
  padding: 30px; /* Espaçamento interno opcional */
  padding-bottom: 45px;
  box-shadow: none !important;
  border: none;
  height: auto;
  max-height: 800px;
}

.card-filtros-mobile {
  overflow-y: scroll;
  background-color: white;
  border-radius: 0px;
  padding-bottom: 45px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  margin-right: 10px;
  height: 90vh;
  width: 100%;
  padding: 20px;
}

.fixed {
  padding: 15px 20px;
  position: fixed;
  width: 100%;
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  border: 0.1px solid rgba(152, 152, 152, 0.3);
  height: 10vh;
}

.btn-limpar-mobile {
  width: 40%;
  margin-right: 10px;
}

.btn-filtrar-mobile {
  width: 40%;
  margin-left: 10px;
}

@media (max-width: 768px) {
}
</style>
