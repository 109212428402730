const modelosVolkswagen = [
  { chave: "", valor: "" },
  { chave: "gol", valor: "Gol" },
  { chave: "voyage", valor: "Voyage" },
  { chave: "saveiro", valor: "Saveiro" },
  { chave: "polo", valor: "Polo" },
  { chave: "virtus", valor: "Virtus" },
  { chave: "golf", valor: "Golf" },
  { chave: "jetta", valor: "Jetta" },
  { chave: "t-cross", valor: "T-Cross" },
  { chave: "taos", valor: "Taos" },
  { chave: "tiguan", valor: "Tiguan" },
  { chave: "nivus", valor: "Nivus" },
  { chave: "amarok", valor: "Amarok" },
  { chave: "up", valor: "Up!" },
  { chave: "fox", valor: "Fox" },
  { chave: "crossfox", valor: "CrossFox" },
  { chave: "spacefox", valor: "SpaceFox" },
  { chave: "touareg", valor: "Touareg" },
  { chave: "passat", valor: "Passat" },
  { chave: "arteon", valor: "Arteon" },
  { chave: "kombi", valor: "Kombi" },
  { chave: "beetle", valor: "Beetle" },
  { chave: "variant", valor: "Variant" },
  { chave: "brasilia", valor: "Brasilia" },
  { chave: "sp2", valor: "SP2" },
  { chave: "santana", valor: "Santana" },
  { chave: "quantum", valor: "Quantum" },
  { chave: "karmann-ghia", valor: "Karmann-Ghia" },
  { chave: "passat-variant", valor: "Passat Variant" },
  { chave: "crossup", valor: "CrossUp!" },
  { chave: "up-pepper", valor: "Up! Pepper" },
  { chave: "golf-gti", valor: "Golf GTI" },
  { chave: "t-roc", valor: "T-Roc" },
  { chave: "id3", valor: "ID.3" },
  { chave: "id4", valor: "ID.4" },
  { chave: "taigun", valor: "Taigun" },
  { chave: "bora", valor: "Bora" },
  { chave: "e-golf", valor: "e-Golf" },
  { chave: "caddy", valor: "Caddy" },
  { chave: "eos", valor: "Eos" },
  { chave: "multivan", valor: "Multivan" },
  { chave: "sharan", valor: "Sharan" },
  { chave: "tiguan-allspace", valor: "Tiguan Allspace" },
  { chave: "transporter", valor: "Transporter" },
  { chave: "id-buzz", valor: "ID.Buzz" },
  { chave: "id-space-vizzion", valor: "ID. Space Vizzion" },
];

export default modelosVolkswagen;
