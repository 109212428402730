<template>
  <div class="rodape">
    <div class="coluna">
      <div class="redes-sociais">
        <h4>Redes Sociais</h4>
        <a target="_blank" href="https://www.instagram.com/lemoscarveiculosdf">
          <v-icon right>mdi mdi-instagram</v-icon>
        </a>
        <a target="_blank" href="https://www.facebook.com/">
          <v-icon right>mdi mdi-facebook</v-icon>
        </a>
        <a target="_blank" :href="`https://api.whatsapp.com/send?phone=${numeroTelefone}&text=${mensagem}`">
          <v-icon right>mdi mdi-whatsapp</v-icon>
        </a>
      </div>
    </div>
    <div class="coluna">
      <v-img lazy-src class="img" src="../assets/LogoLemosCar.png" alt="Logo"></v-img>
      <p class="mt-2">copyright &copy 2023 Todos os direitos reservados</p>
    </div>
    <div class="coluna">
      <div class="t">
        <h4>(61) 99444-2213</h4>
        <h4>(61) 99218-2919</h4>
        <p>faleconoscolemoscar@gmail.com</p>
        <p>Brasilia DF</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
     numeroTelefone: "5561994442213",
    mensagem: encodeURIComponent(
        "Olá, entrei em contato através do site. Gostaria de informações."
      ),
  })
}
</script>
<style scoped>
.img {
  margin: auto;
  width: 180px;
  filter: opacity(0.5);
}
.rodape {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  width: 100%;
  margin-top: 110px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03) !important;

  flex-wrap: wrap; /* Adicione a propriedade flex-wrap para permitir que os itens se movam para a próxima linha */
  padding: 20px;
}

.coluna {
  flex: 1;
  text-align: center !important;
  margin-bottom: 10px; /* Adicione margem inferior para separar as colunas */
}

.coluna h4 {
  margin-bottom: 5px;
}

.coluna p {
  text-align: center !important;
}
.redes-sociais {
  display: flex;
  justify-content: center;
}

.icone-rede-social {
  margin: 0 5px;
  /* Adicione estilos específicos para os ícones das redes sociais, como fontes ou imagens */
}

@media (max-width: 600px) {
  /* Adicione regras de estilo específicas para telas menores (por exemplo, 600 pixels) */
  .coluna {
    flex: 100%; /* Em telas menores, faz com que cada coluna ocupe 100% da largura */
  }
}
</style>
