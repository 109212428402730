import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { db } from "@/firebase.js";
import { collection, getDocs, query, limit, startAfter, orderBy, where } from "firebase/firestore";
import listaDirecao from "../listas/direcao";
import { mask } from "vue-the-mask";
import { CapitalizacaoMixin } from "../mixins/capitalizacaoMixin";
import { BRL } from "../mixins/BRL";
import { Quilometragem } from "../mixins/Quilometragem";
import Paginator from "vuejs-paginator";
import { nextTick } from "vue"; // Certifique-se de importar nextTick

export default {
  directives: { mask },
  mixins: [CapitalizacaoMixin, BRL, Quilometragem],
  props: ["value", "search"],
  components: {
    Paginator
  },
  data() {
    return {
      carrosEncontrados: null,
      cars: [],
      carsFiltrados: [],
      drawer: false,
      isLargeScreen: this.$vuetify.breakpoint.width > 1300,
      panel: false,
      panelAno: false,
      panelCategorias: false,
      panelPreco: false,
      mostrarFiltrosMobile: false,
      isMobile: this.$vuetify.breakpoint.width < 700,
      listaDirecoes: listaDirecao.map((marca) => marca.valor),
      anosDisponiveis: [],
      marcasDisponiveis: [],
      carroceriasDisponiveis: [],
      modelos: {},
      isNotebook: this.$vuetify.breakpoint.mdAndDown,
      lastVisible: null,
      carIds: new Set(), 
      isLoading: false,  
      hasMoreCars: true, 
      initialLoadDone: false, 
      btnIsLoading: false,
    };
  },
  methods: {
    changeDrawerFunction() {
      this.drawer = !this.drawer;
    },

    setDrawerFunction(v) {
      this.drawer = v;
    },

    setCarrosFiltrados(v) {
      this.carsFiltrados = v;
    },

    goToViewPage(carId, carModelo) {
      this.$router.push({ name: "view", params: { id: carId, modelo: carModelo } });
    },
    
    buildQuery(startAfterDoc = null) {
      const carsCollection = collection(db, "cars");
      let carsQuery = query(carsCollection, limit(12));

      if (this.nomePagina === "destaques") {
        carsQuery = query(carsCollection, where('Destaque', '==', true), limit(12));
      } else if (this.nomePagina === "mais-procurados") {
        carsQuery = query(carsCollection, where('MaisProcurado', '==', true), limit(12));
      } else if (this.nomePagina === "home") {
        carsQuery = query(carsCollection, where('Recentes', '==', true), orderBy('Modelo'), limit(12));
      } else {
        if (this.search) {
          carsQuery = query(carsCollection, where('Modelo', '==', this.search.toLowerCase()), limit(12));
        }
      }
    
      if (startAfterDoc) {
        carsQuery = query(carsQuery, startAfter(startAfterDoc));
      }

      return carsQuery;
    },

    async getCars() {
      if (this.isLoading) return;  
      this.isLoading = true;
    
      const carsQuery = this.buildQuery();
    
      try {
        const snapshot = await getDocs(carsQuery);
        if (snapshot.empty) {
          this.hasMoreCars = false; 
          return;
        }
    
        const storage = getStorage();
        this.cars = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const carData = {
              id: doc.id,
              ...doc.data(),
            };
    
            if (carData.Fotos && carData.Fotos.length > 0) {
              const storageRef = ref(storage, carData.Fotos[0]);
              carData.downloadURL = await getDownloadURL(storageRef);
            }
    
            return carData;
          })
        );

        this.carsFiltrados = [...this.cars];
        this.carrosEncontrados = this.carsFiltrados.length;
        this.anosDisponiveis = [...new Set(this.cars.map((car) => car.Ano))];
        this.marcasDisponiveis = [...new Set(this.cars.map((car) => car.Marca))];
        this.carroceriasDisponiveis = [...new Set(this.cars.map((car) => car.Carroceria))];

        this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
        this.initialLoadDone = true; 
    
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
  
    async loadMoreCars() {
      if (!this.initialLoadDone || !this.hasMoreCars || this.isLoading) return;
      this.btnIsLoading = true;
      this.isLoading = true;
    
      const carsQuery = this.buildQuery(this.lastVisible);
    
      try {
        const snapshot = await getDocs(carsQuery);
    
        if (snapshot.empty) {
          this.hasMoreCars = false; 
          return;
        }
    
        const storage = getStorage();
        const newCars = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const carData = {
              id: doc.id,
              ...doc.data(),
            };
    
            if (carData.Fotos && carData.Fotos.length > 0) {
              const storageRef = ref(storage, carData.Fotos[0]);
              carData.downloadURL = await getDownloadURL(storageRef);
            }
    
            return carData;
          })
        );
    
        const filteredNewCars = newCars.filter(car => !this.carIds.has(car.id));
        filteredNewCars.forEach(car => this.carIds.add(car.id));
        
        // Salva o estado anterior da lista de carros filtrados
        const previousCarsFiltrados = [...this.carsFiltrados];
        
        this.cars = [...this.cars, ...filteredNewCars];
        this.carsFiltrados = [...this.cars];
        this.carrosEncontrados = this.carsFiltrados.length;

        // Chama a função para comparar e rolar se necessário
        this.scrollToBottomIfListUpdated(previousCarsFiltrados);
      
        this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
        this.btnIsLoading = false;
    
      } catch (error) {
        this.btnIsLoading = false;

      } finally {
        this.isLoading = false;
        this.btnIsLoading = false;
      }
    },

    scrollToBottomIfListUpdated(previousCarsFiltrados) {
      if (this.carsFiltrados.length > previousCarsFiltrados.length) {
        nextTick(() => {
          const scrollContainer = this.$refs.scrollContainer;
    
          // Determina o valor de `top` com base no tamanho da tela
          const scrollTopValue = this.isLargeScreen ? 900 : 1500;
    
          scrollContainer.scrollTo({
            top: scrollTopValue,
            behavior: "smooth"
          });
        });
      }
    }
  },

  computed: {
    modelosDisponiveis: {
      get() {
        return this.modelos[this.filtroMarca] || [];
      },
      set(value) {},
    },
    nomePagina: {
      get() {
        return this.value;
      },
      set(val) {},
    },
  },

  watch: {
    "$vuetify.breakpoint.width"() {
      this.isLargeScreen = this.$vuetify.breakpoint.width > 1300;
      this.isMobile = this.$vuetify.breakpoint.width < 705;
    },
  },
  
  mounted() {
    if (this.search) {
      this.filtroModelo = this.search;
    }
    this.getCars(); 
  },
};
