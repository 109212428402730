<template>
  <v-app  id="app">
    <v-main >
      <div v-if="getRouteName() != 'login' && getRouteName() != 'sign'">
        <Menu></Menu>
      </div>
      <router-view></router-view>
    </v-main>

    <div v-if="getRouteName() != 'login' && getRouteName() != 'sign'">
      <whats></whats>
    </div>

    <div
      class="rodape"
      v-if="getRouteName() != 'login' && getRouteName() != 'sign'"
    >
      <Rodape></Rodape>
    </div>
  </v-app>
</template>

<script>
import Menu from "./views/Menu.vue";
import Rodape from "./views/Rodape.vue";
import Whats from "./views/whats.vue";
import Meta from "vue-meta";
export default {
  data() {
    return {
      routeName: "",
    };
  },

  methods: {
    getRouteName() {
      return this.$route.name;
      
    },
  },

  components: { Menu, Rodape, Whats },
};
</script>

<style>
#app {
  background-color: #f5f5f5 !important;
  font-family: "Inter", sans-serif;
}
/* Reset das margens do body */
body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

v-main {
  flex: 1;
}

.rodape {
  margin-top: auto;
}

p {
  margin: 0 !important;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent; /* Cor da parte do meio */
  border-radius: 0px;
}

::-webkit-scrollbar-track {
  background-color: black; /* Cor do restante do scrollbar */
  border-radius: 0px;
}

.theme--dark {
  color: #ffffff;
}

/* Adicione outros estilos globais, se necessário */
</style>
