import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import Estoque from '../views/Estoque.vue';
import Home from '../views/Home.vue';
import AuthView from '../views/Auth.vue';
import Create from '../views/Create.vue';
import ListCars from '../views/ListCars.vue';
import ViewCar from '../views/ViewCar.vue';
import About from '../views/AboutView';
import OndeNosEncontramos from '../views/OndeNosEncontramos';
import Loading from '@/views/Loading';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Lemos Car Veículos',
      metaTags: [
        { name: 'description', content: 'Página Inicial da Lemos Car Veículos' },
        { name: 'keywords', content: 'carros, veículos, novos, seminovos, lemos, car' }
      ]
    }
  },
  {
    path: '/estoque',
    name: 'estoque',
    component: Estoque,
    meta: {
      title: 'Estoque',
      metaTags: [
        { name: 'description', content: 'Nosso estoque de veículos' },
        { name: 'keywords', content: 'estoque, carros, veículos' }
      ]
    }
  },
  {
    path: '/editar/:id',
    name: 'editar-carro',
    component: Create,
    meta: {
      title: 'Editar Carro',
      requiresAuth: true
    }
  },
  {
    path: '/view/:id/:modelo',
    name: 'view',
    component: ViewCar,
    meta: {
      title: 'Visualizar Carro',
      metaTags: [
        { name: 'description', content: 'Visualize detalhes do carro' },
        { name: 'keywords', content: 'visualizar, carro, detalhes' }
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: 'Sobre Nós',
      metaTags: [
        { name: 'description', content: 'Sobre a Lemos Car Veículos' },
        { name: 'keywords', content: 'sobre, empresa, Lemos Car' }
      ]
    }
  },
  {
    path: '/destaques',
    name: 'destaques',
    component: Estoque,
    meta: {
      title: 'Destaques',
      metaTags: [
        { name: 'description', content: 'Veja nossos carros em destaque' },
        { name: 'keywords', content: 'destaques, carros' }
      ]
    }
  },
  {
    path: '/mais-procurados',
    name: 'mais-procurados',
    component: Estoque,
    meta: {
      title: 'Mais Procurados',
      metaTags: [
        { name: 'description', content: 'Confira os carros mais procurados' },
        { name: 'keywords', content: 'mais procurados, carros' }
      ]
    }
  },
  {
    path: '/login',
    name: 'login',
    component: AuthView,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/create',
    name: 'create',
    component: Create,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/list',
    name: 'list',
    component: ListCars,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/local',
    name: 'local',
    component: OndeNosEncontramos,
    meta: {
      title: 'Onde Nos Encontramos',
      metaTags: [
        { name: 'description', content: 'Encontre nossa localização' },
        { name: 'keywords', content: 'localização, onde estamos' }
      ]
    }
  },
  {
    path: '/loading',
    name: 'loading',
    component: Loading,
    meta: {
      requiresAuth: false
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  // Atualiza o título da página
  document.title = to.meta.title || 'Lemos Car Veículos';

  // Atualiza as meta tags
  if (to.meta.metaTags) {
    to.meta.metaTags.forEach(tag => {
      const existingTag = document.querySelector(`meta[name="${tag.name}"]`) || document.querySelector(`meta[property="${tag.property}"]`);
      if (existingTag) {
        existingTag.setAttribute('content', tag.content);
      } else {
        const newTag = document.createElement('meta');
        newTag.setAttribute('name', tag.name);
        newTag.setAttribute('content', tag.content);
        document.head.appendChild(newTag);
      }
    });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      alert('Você não possui acesso');
      next('/login');
    }
  } else {
    next();
  }
});

export default router;
